import { Link } from "react-router-dom";
import { Button } from "../../exports";

import "./commercial-item.scss";

function CommercialItem(props) {

    function handleClick(e) {

        const selector = e.target.closest('.video-item');

        if (selector.classList.contains("video-item--expanded")) {
            selector.classList.remove("video-item--expanded");
        } else {
            document.querySelectorAll('.video-item--expanded').forEach(item => {
                item.classList.remove('video-item--expanded');
            });
            selector.classList.add("video-item--expanded");
        }
    }

    return (
        <li className="video-item" onClick={(e) => {handleClick(e)}}>
            <img className="video-thumbnail" src={props.video} alt=""/>
            <div className="video-contents">
                <h2 className="video-contents__title">{props.title}</h2>
                <p className="video-contents__description">{props.desc}</p>

                {/* <Link className="video-contents__button" role="button" onClick={() => {}} to={`/commercial/${props.url}`}>WATCH VIDEO</Link> */}
                <Button buttonDest={`/commercial/${props.url}`} buttonColor="primary">WATCH VIDEO</Button>

            </div>
        </li>
    );
}

export default CommercialItem;