const VideoDataMusic = {

    "demon": {
        thumbnails:
            [
                "/resources/thumbnails/music-videos/demon/demon-thumbnail-1.jpg",
                "/resources/thumbnails/music-videos/demon/demon-thumbnail-2.jpg",
                "/resources/thumbnails/music-videos/demon/demon-thumbnail-3.jpg",
                "/resources/thumbnails/music-videos/demon/demon-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails-small/demon-thumbnail-1.jpg",

        title: "DEMON",

        artist: "YH56ST",

        link: "https://player.vimeo.com/video/586009304?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=234180c738",
    },

    "sno-on-fire": {
        thumbnails:
            [
                "/resources/thumbnails/music-videos/sno-on-fire/sno-on-fire-thumbnail-1.jpg",
                "/resources/thumbnails/music-videos/sno-on-fire/sno-on-fire-thumbnail-2.jpg",
                "/resources/thumbnails/music-videos/sno-on-fire/sno-on-fire-thumbnail-3.jpg",
                "/resources/thumbnails/music-videos/sno-on-fire/sno-on-fire-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails/music-videos/sno-on-fire/sno-on-fire-thumbnail-1.jpg",

        title: "SNO ON FIRE",

        artist: "SAMMY SNO",

        link: "https://player.vimeo.com/video/790581716?h=0d4bbbd42b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },

    "patience": {
        thumbnails:
            [
                "/resources/thumbnails/music-videos/patience/patience-thumbnail-1.jpg",
                "/resources/thumbnails/music-videos/patience/patience-thumbnail-2.jpg",
                "/resources/thumbnails/music-videos/patience/patience-thumbnail-3.jpg",
                "/resources/thumbnails/music-videos/patience/patience-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails/music-videos/patience/patience-thumbnail-1.jpg",

        title: "PATIENCE",

        artist: "T-RAVILL FEAT. JGRIFF",

        link: "https://player.vimeo.com/video/790594203?h=bc0e0958dd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },

    "purple-face-cypher": {
        thumbnails:
            [
                "/resources/thumbnails/music-videos/purple-face-cypher/purple-face-cypher-thumbnail-1.jpg",
                "/resources/thumbnails/music-videos/purple-face-cypher/purple-face-cypher-thumbnail-2.jpg",
                "/resources/thumbnails/music-videos/purple-face-cypher/purple-face-cypher-thumbnail-3.jpg",
                "/resources/thumbnails/music-videos/purple-face-cypher/purple-face-cypher-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails/music-videos/purple-face-cypher/purple-face-cypher-thumbnail-1.jpg",

        title: "PURPLE FACE CYPHER",

        artist: "VARIOUS ARTISTS",

        link: "https://player.vimeo.com/video/790593084?h=38e9e4be0e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },

    "red-carpet": {
        thumbnails:
            [
                "/resources/thumbnails/music-videos/red-carpet/red-carpet-thumbnail-1.jpg",
                "/resources/thumbnails/music-videos/red-carpet/red-carpet-thumbnail-2.jpg",
                "/resources/thumbnails/music-videos/red-carpet/red-carpet-thumbnail-3.jpg",
                "/resources/thumbnails/music-videos/red-carpet/red-carpet-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails-small/red-carpet-thumbnail-1.jpg",

        title: "RED CARPET",

        graphic: "/resources/graphics/red-carpet-text.png",

        artist: "ANDY THE CHEF",

        link: "https://player.vimeo.com/video/586015800?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=3d087df12d",
    },

    "murder": {
        thumbnails:
            [
                "/resources/thumbnails/music-videos/murder/murder-thumbnail-1.jpg",
                "/resources/thumbnails/music-videos/murder/murder-thumbnail-2.jpg",
                "/resources/thumbnails/music-videos/murder/murder-thumbnail-3.jpg",
                "/resources/thumbnails/music-videos/murder/murder-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails-small/murder-thumbnail-1.jpg",

        title: "MURDER",

        artist: "WVLNGTH",

        link: "https://player.vimeo.com/video/586012517?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=812e2293b2",
    },

    // "how-im-feelin": {
    //     thumbnails:
    //         [
    //             "/resources/thumbnails/music-videos/how-im-feelin/how-im-feelin-thumbnail-1.jpg",
    //             "/resources/thumbnails/music-videos/how-im-feelin/how-im-feelin-thumbnail-2.jpg",
    //             "/resources/thumbnails/music-videos/how-im-feelin/how-im-feelin-thumbnail-3.jpg",
    //             "/resources/thumbnails/music-videos/how-im-feelin/how-im-feelin-thumbnail-4.jpg",
    //         ],

    //     preview: "/resources/thumbnails-small/how-im-feelin-thumbnail-1.jpg",

    //     title: "HOW I'M FEELIN",

    //     artist: "ANDY THE CHEF",

    //     link: "https://player.vimeo.com/video/594841028?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=1108814f26",
    // },

    "rip-2-the-real-1s": {
        thumbnails:
            [
                "/resources/thumbnails/music-videos/rip/rip-thumbnail-1.jpg",
                "/resources/thumbnails/music-videos/rip/rip-thumbnail-2.jpg",
                "/resources/thumbnails/music-videos/rip/rip-thumbnail-3.jpg",
                "/resources/thumbnails/music-videos/rip/rip-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails-small/rip-thumbnail-1.jpg",

        title: "RIP 2 THE REAL 1'S",

        artist: "OZ, JOJOTHEDON, T-RAVILL",

        link: "https://player.vimeo.com/video/597301059?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=3e7347948c",
    },

    // "ring-ring": {
    //     thumbnails:
    //         [
    //             "/resources/thumbnails/music-videos/ring-ring/ring-ring-thumbnail-1.jpg",
    //             "/resources/thumbnails/music-videos/ring-ring/ring-ring-thumbnail-2.jpg",
    //             "/resources/thumbnails/music-videos/ring-ring/ring-ring-thumbnail-3.jpg",
    //             "/resources/thumbnails/music-videos/ring-ring/ring-ring-thumbnail-4.jpg",
    //         ],

    //     preview: "/resources/thumbnails-small/ring-ring-thumbnail-1.jpg",

    //     title: "RING RING",

    //     artist: "BINKS",

    //     link: "https://player.vimeo.com/video/586020141?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=5ba3af07cc",
    // },

    // "the-city": {
    //     thumbnails:
    //         [
    //             "/resources/thumbnails/the-city-thumbnail-1.jpg",
    //             "/resources/thumbnails/the-city-thumbnail-2.jpg",
    //             "/resources/thumbnails/the-city-thumbnail-3.jpg",
    //             "/resources/thumbnails/the-city-thumbnail-4.jpg",
    //         ],

    //     title: "THE CITY (FEAT. LEVI)",

    //     artist: "ANDY THE CHEF",
    // }
}

module.exports = VideoDataMusic;