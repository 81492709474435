import { useState, useEffect, useRef } from "react";

import { Layout, PhotoData } from "../../exports";

import "./photography-desktop.scss";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // remember callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // set up interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function PhotographyDesktop() {
    const categoriesLength = Object.keys(PhotoData).length;
    const thumbnails = [];
    const titles = [];
    const urls = [];

    for(let i = 0; i < categoriesLength; i++) {
        let key = Object.keys(PhotoData)[i]
        thumbnails.push(PhotoData[key].thumbnail);
        titles.push(PhotoData[key].title);
        urls.push(key);
    }

    // const thumbnails = PhotoData.portraits.sources;

    const [ carouselPos, setCarouselPos ] = useState(0);
    const [ moving, setMoving ] = useState(false);
    const [ forwards, setForwards ] = useState();
    const [ max, setMax ] = useState(100);

    
    // TODO: responsiveness

    useInterval(() => {
        if (!forwards && carouselPos <= -10) {
            setCarouselPos(carouselPos + 10);
        } else if (forwards && carouselPos >= max){
            setCarouselPos(carouselPos - 10);
        }
    }, moving ? 10 : null);

    useEffect(() => {
        
        function getSize() {
            setMax((document.getElementById("carousel-container").getBoundingClientRect().width - window.innerWidth - 5) * -1);
        }

        getSize();

        window.addEventListener("resize", getSize);

        return() => {
            window.removeEventListener("resize", getSize)
        }
    }, []);

    return (
        <Layout cn={"photography"} current="photography" footerColor="medium-dark">
            <div className="carousel">
                <div className="carousel-container"
                    style={{
                        left: `${carouselPos}px`
                    }} id="carousel-container">
                    {thumbnails.map((item, index) => {
                        return(
                        <a key={index} href={`/photography/${urls[index]}`}>
                            <div className="carousel-slide" key={index} style={{
                                backgroundImage: `url(${item})`
                            }}>
                                <div className="carousel-slide__overlay"></div>
                                <h2 className="carousel-slide__title">{titles[index]}</h2>
                            </div>
                        </a>)
                    })}
                </div>

                <div className="carousel-controls">
                    <div className={`carousel-controls__prev${carouselPos >= 0 ? " carousel-controls__prev--hidden" : ""}`} src="/resources/graphics/carousel-next.svg" onMouseOver={() => {setForwards(false); setMoving(true);}} onMouseOut={() => setMoving(false)}>
                        <img src="/resources/graphics/carousel-prev.svg" alt="" />
                    </div>

                    <div className={`carousel-controls__next${carouselPos <= max ? " carousel-controls__next--hidden" : ""}`} src="/resources/graphics/carousel-next.svg" onMouseOver={() => {setForwards(true); setMoving(true)}} onMouseOut={() => setMoving(false)}>
                        <img src="/resources/graphics/carousel-next.svg" alt="" />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PhotographyDesktop
