const PhotoData = {
    portraits: {
        title: "Portraits",
        thumbnail: "/resources/images/portraits/_thumbnail.jpg",
        mobileThumbnail: "/resources/images/portraits/_thumbnail-mobile.jpg",
        description: "Show off your best look with stunning portraits for any occasion",
        sources: [
            ["/resources/images/portraits/portrait1.jpg", 871],
            ["/resources/images/portraits/portrait2.jpg", 864],
            ["/resources/images/portraits/portrait3.jpg", 863],
            ["/resources/images/portraits/portrait4.jpg", 864],
            ["/resources/images/portraits/portrait5.jpg", 864],
            ["/resources/images/portraits/portrait6.jpg", 864],
            ["/resources/images/portraits/portrait7.jpg", 864],
            ["/resources/images/portraits/portrait8.jpg", 834],
            ["/resources/images/portraits/portrait9.jpg", 864],
            ["/resources/images/portraits/portrait10.jpg", 863],
            ["/resources/images/portraits/portrait11.jpg", 864],
            ["/resources/images/portraits/portrait12.jpg", 863],
            ["/resources/images/portraits/portrait13.jpg", 1619],
            ["/resources/images/portraits/portrait14.jpg", 864],
            ["/resources/images/portraits/portrait15.jpg", 863],
            ["/resources/images/portraits/portrait16.jpg", 864],
            ["/resources/images/portraits/portrait17.jpg", 864],
            ["/resources/images/portraits/portrait18.jpg", 1512],
            ["/resources/images/portraits/portrait19.jpg", 771],
            ["/resources/images/portraits/portrait20.jpg", 1619],
            ["/resources/images/portraits/portrait21.jpg", 864],
            ["/resources/images/portraits/portrait22.jpg", 863],
            ["/resources/images/portraits/portrait23.jpg", 864],
            ["/resources/images/portraits/portrait24.jpg", 864],
            ["/resources/images/portraits/portrait25.jpg", 600]
        ],
    },

    events: {
        title: "Events",
        thumbnail: "/resources/images/events/_thumbnail.jpg",
        mobileThumbnail: "/resources/images/events/_thumbnail-mobile.jpg",
        description: "Our event photos capture the best moments of parties, concerts, or any other events",
        sources: [
            ["/resources/images/events/events3.jpg", 863],
            ["/resources/images/events/events4.jpg", 863],
            ["/resources/images/events/events5.jpg", 863],
            ["/resources/images/events/events6.jpg", 863],
            ["/resources/images/events/events7.jpg", 863],
            ["/resources/images/events/events13.jpg", 747],
            ["/resources/images/events/events14.jpg", 863],
            ["/resources/images/events/events15.jpg", 864],
            ["/resources/images/events/events16.jpg", 863],
            ["/resources/images/events/events17.jpg", 863],
            ["/resources/images/events/events18.jpg", 864],
            ["/resources/images/events/events19.jpg", 864],
            ["/resources/images/events/events20.jpg", 863],
            ["/resources/images/events/events21.jpg", 1397],
            ["/resources/images/events/events22.jpg", 864],
            ["/resources/images/events/events23.jpg", 863]
        ],
    },

    
    commercial: {
        title: "Commercial",
        thumbnail: "/resources/images/commercial/_thumbnail.jpg",
        mobileThumbnail: "/resources/images/commercial/_thumbnail-mobile.jpg",
        description: "Advertise or promote your business with professional commercial photography",
        sources: [
            ["/resources/images/commercial/product1.jpg", 865],
            ["/resources/images/commercial/product2.jpg", 864],
            ["/resources/images/commercial/product3.jpg", 865],
            ["/resources/images/commercial/product4.jpg", 719],
            ["/resources/images/commercial/product5.jpg", 1920],
            ["/resources/images/commercial/product6.jpg", 863],
            ["/resources/images/commercial/product7.jpg", 864],
            ["/resources/images/commercial/product8.jpg", 1920],
            ["/resources/images/commercial/product9.jpg", 863],
            ["/resources/images/commercial/product10.jpg", 863],
            ["/resources/images/commercial/product11.jpg", 863],
            ["/resources/images/commercial/product12.jpg", 864],
            ["/resources/images/commercial/product13.jpg", 1080],
            ["/resources/images/commercial/product14.jpg", 1080],
            ["/resources/images/commercial/product15.jpg", 1080],
            ["/resources/images/commercial/product16.jpg", 1080],
            ["/resources/images/commercial/product17.jpg", 1080],
            ["/resources/images/commercial/product18.jpg", 1080],
            ["/resources/images/commercial/product19.jpg", 1080],
        ],
    },
    
    "real-estate": {
        title: "Real Estate",
        thumbnail: "/resources/images/real-estate/_thumbnail.jpg",
        mobileThumbnail: "/resources/images/real-estate/_thumbnail-mobile.jpg",
        description: "Showcase your property to buyers with real estate photography",
        sources: [
            ["/resources/images/real-estate/realestate1.jpg", 1620],
            ["/resources/images/real-estate/realestate2.jpg", 1620],
            ["/resources/images/real-estate/realestate3.jpg", 1620],
            ["/resources/images/real-estate/realestate4.jpg", 1620],
            ["/resources/images/real-estate/realestate5.jpg", 1621],
            ["/resources/images/real-estate/realestate6.jpg", 1620]
        ],
    },
    
    landscapes: {
        title: "Landscapes",
        thumbnail: "/resources/images/landscapes/_thumbnail.jpg",
        mobileThumbnail: "/resources/images/landscapes/_thumbnail-mobile.jpg",
        sources: [
            ["/resources/images/landscapes/landscapes1.jpg", 1510],
            ["/resources/images/landscapes/landscapes2.jpg", 864],
            ["/resources/images/landscapes/landscapes3.jpg", 863],
            ["/resources/images/landscapes/landscapes4.jpg", 1620],
            ["/resources/images/landscapes/landscapes5.jpg", 863],
            ["/resources/images/landscapes/landscapes6.jpg", 863]
        ],
    },
}

module.exports = PhotoData;
