import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Layout, Button, CommercialList, VideoDataCommercial, BiographyData } from "../../exports";

import "./commercial.scss";


function Commercial() {

	const infoParagraph = BiographyData.commercialParagraph;

	const [loadNum, setLoadNum] = useState(4);

	const max = Object.keys(VideoDataCommercial).length;

	const scrollTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	const loadMore = () => {
		if (loadNum >= max) {
			return;
		} else if (loadNum + 4 <= max) {
			setLoadNum(loadNum + 4);
		} else if (loadNum < max) {
			setLoadNum(loadNum + (max - loadNum));
		}
	}

	useEffect(() => {

        function handleScroll() {
            if (document.body.scrollTop >= document.documentElement.scrollHeight - (window.innerHeight / 2)) {
                loadMore();
            }
        }

		document.body.addEventListener("scroll", handleScroll);

        return(() => {
            document.body.removeEventListener("scroll", handleScroll);
        });
	})

	return (
		<Layout cn={"showcase"} footerColor="dark">
			<div className="commercial">
				<h1 className="section-header">COMMERCIAL</h1>

				<div className="info">
					<p className="info__paragraph">{infoParagraph}<br /><br />We do <b>Advertisements, Weddings, Sporting Events, Concerts, and more.</b></p>

					<Button buttonDest="/contact" buttonColor="primary" buttonOutlined>BOOKING & INQUIRIES</Button>
				</div>

				<CommercialList loadNum={loadNum} max={max} dataSet={VideoDataCommercial} />
				
				<div className="back-to-top" onClick={() => {scrollTop()}}><span>BACK TO TOP</span></div>
			</div>
		</Layout>
	);
}

export default Commercial;
