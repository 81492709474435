import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { Layout, PhotoData } from "../../exports";

import "./photography-desktop-viewer.scss";


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}

function PhotographyDesktopViewer() {

    const [loaded, setLoaded] = useState(false);
    const [ windowWidth ] = useWindowSize();
    const { collection } = useParams();

    const collectionTitle = PhotoData[collection].title;
    const urls = PhotoData[collection].sources;

    const slides = [];
    
    for(let i = 0; i < urls.length; i++) {
        
        const img = <img id={`img-${i}`} key={i} className="viewer-carousel-slide" src={urls[i][0]} alt={`${collectionTitle} ${i + 1}`} />

        slides.push(
            [img]
        );
    }

    function updateSizes() {    
        
        const resizeRatio = 2.5;
        
        for (let i = 0; i < urls.length; i++) {
            const naturalWidth = document.getElementById(`img-${i}`).naturalWidth;
            const renderedWidth = naturalWidth / resizeRatio;
            
            slides[i].push(renderedWidth);
        }
        
        const windowWidth = document.getElementsByClassName("viewer-photos")[0].offsetWidth;

        let index = 0;

        while (index < urls.length - 1) {
            let combinedWidth = 0;
            let count = 0;

            for (let i = index; i < urls.length; i++) {
                if (combinedWidth + slides[i][1] < windowWidth) {
                    count++;

                    combinedWidth += slides[i][1];
                } else {
                    break;
                }
            }

            // TODO: add extra logic to allow for some whitespace if the 1 or 2 images in the last row would get too big
            for (let i = index; i < index + count; i++) {
                const diff = windowWidth - combinedWidth;
                const widthRatio = slides[i][1] / combinedWidth;
                const widthIncrease = Math.floor(diff * widthRatio);

                document.getElementById(`img-${i}`).style.width = slides[i][1] + widthIncrease + "px";
                document.getElementById(`img-${i}`).style.animation = `fade 0.4s ease-in ${0.085 * (i+1)}s 1 normal forwards`;
            }
            index += count;
        }
    }

    useEffect(() => {
        const loadImage = image => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image();
                loadImg.src = image[0].props.src;
                loadImg.onload = () =>
                    setTimeout(() => {
                        resolve(image.url)
                    }, 750);

                loadImg.onerror = err => reject(err);
            })
        }

        Promise.all(slides.map(image => loadImage(image)))
            .then(() => setLoaded(true))
            .catch(err => console.log("Failed to load images", err));
    }, []);

    useEffect(() => {
        if(loaded) {
            updateSizes();
        }
    }, [loaded]);

    useEffect(() => {
        if(loaded) {
            updateSizes();
        }
    }, [windowWidth]);


    return (
        <Layout cn={"photography"} current="photography" footerColor="dark">
            <div className="viewer-wrapper">
                <div className="viewer-carousel-tag">
                    <Link to="/photography">
                        <svg width="38" height="20" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M15 4C15 3.88214 14.9436 3.76911 14.8431 3.68577C14.7426 3.60244 14.6064 3.55562 14.4643 3.55562L1.82977 3.55562L5.20134 0.759566C5.25115 0.718249 5.29065 0.669199 5.31761 0.615216C5.34456 0.561233 5.35844 0.503374 5.35844 0.444943C5.35844 0.386513 5.34456 0.328654 5.31761 0.274671C5.29065 0.220688 5.25115 0.171638 5.20134 0.130321C5.15154 0.089004 5.09241 0.0562298 5.02733 0.0338693C4.96226 0.0115088 4.89251 0 4.82208 0C4.75164 0 4.6819 0.0115088 4.61682 0.0338693C4.55175 0.0562298 4.49262 0.089004 4.44282 0.130321L0.157376 3.68538C0.10749 3.72666 0.0679111 3.77569 0.0409059 3.82968C0.0139007 3.88367 2.94372e-09 3.94155 2.98738e-09 4C3.03103e-09 4.05845 0.0139007 4.11633 0.0409059 4.17032C0.0679111 4.2243 0.10749 4.27334 0.157376 4.31462L4.44282 7.86968C4.49262 7.911 4.55175 7.94377 4.61682 7.96613C4.6819 7.98849 4.75164 8 4.82208 8C4.89251 8 4.96226 7.98849 5.02733 7.96613C5.09241 7.94377 5.15154 7.911 5.20134 7.86968C5.25115 7.82836 5.29065 7.77931 5.31761 7.72533C5.34456 7.67135 5.35844 7.61349 5.35844 7.55506C5.35844 7.49662 5.34456 7.43877 5.31761 7.38478C5.29065 7.3308 5.25115 7.28175 5.20134 7.24043L1.82977 4.44438L14.4643 4.44438C14.6064 4.44438 14.7426 4.39756 14.8431 4.31423C14.9436 4.23089 15 4.11786 15 4Z" />
                        </svg>
                    </Link>
                    <div className="viewer-carousel-tag__title-container">
                        <h1 className="viewer-carousel-tag__title">{collectionTitle}</h1>
                    </div>
                </div>
                <div className="viewer-photos">
                    {loaded ? 
                        slides
                    : <h2 className="viewer-photos-loading">Loading...</h2>}
                </div>
            </div>
        </Layout>
    );
}

export default PhotographyDesktopViewer;
