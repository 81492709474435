import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import "./nav.scss";

function Nav(props) {

	const [navExpanded, setNavExpanded] = useState(false)

	useEffect(() => {
		if(window.innerWidth >= 1024) {
			if (document.documentElement.scrollTop > 10) {
				setNavExpanded(true);
			} else {
				setNavExpanded(false);
			}
		}
		
		window.addEventListener("scroll", () => {
			if (window.innerWidth >= 1024) {
				if(document.documentElement.scrollTop> 10) {
					setNavExpanded(true);
				} else {
					setNavExpanded(false);
				}
			}
		});

	}, []);

	return (
		<nav>
			<div className={"nav"}>
				<img
					className={`nav-burger${props.menuState ? " nav-burger--hidden" : ""}`}
					src="/resources/graphics/burger.svg"
					alt=""
					onClick={props.openCloseMenu}
				/>
				<Link to="/">
					<img
						className="nav-logo"
						src="/resources/graphics/logo-mobile-new.png"
						alt=""
					/>
				</Link>
				<img
					className="nav-buffer"
					src="/resources/graphics/burger.svg"
					alt=""
				/>
			</div>

			<div className={`nav-desktop${navExpanded ? " nav-desktop--expanded" : ""}${props.menuColor ? ` nav-${props.menuColor}` : ""}`}>
				<Link to="/">
					<img
						className="nav-logo"
						src={`/resources/graphics/logo-new${props.menuColor === "light" ? "-dark" : ""}.png`}
						alt=""
					/>
				</Link>
				<ul>
					<li className={props.current === "weddings" ? "current" : ""}>
						<Link to="/weddings">Weddings</Link>
					</li>

					<li className={props.current === "commercial" ? "current" : ""}>
						<Link to="/commercial">Commercial</Link>
					</li>

					<li className={props.current === "musicvideos" ? "current" : ""}>
						<Link to="/music-videos">Music Videos</Link>
					</li>

					<li className={props.current === "photography" ? "current" : ""}>
						<Link to="/photography">Photography</Link>
					</li>

					{/* <li className={props.current === "bio" ? "current" : ""}>
						<Link to="/bio">Bio</Link>
					</li> */}

					<li className={props.current === "contact" ? "current" : ""}>
						<Link to="/contact">Contact</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
}

export default Nav;
