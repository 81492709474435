const data = {
    breakpoints: {
        'medium': 786,
        'large': 1024,
    },

    commercialDescription: "Make your business or event stand out with professional advertisements, photos, and more.",

    musicVideoDescription: "Bring your dreams to life with music videos crafted from expert skill and creativity.",
}

module.exports = data;