import { useState, useEffect, useRef } from "react";

import { useParams } from "react-router";
import { useSelector } from 'react-redux'

import { Layout, DesktopVideo, DesktopVideoInfo, DesktopMediaAbout, VideoDataCommercial, BiographyData } from "../../exports";

import "../../components/common/desktop-media-viewer.scss";

function CommercialDesktopViewer() {

    const pageWidth = useSelector(state => state.pageWidth.value)

    const videoURL = useParams();

    if(videoURL.video === undefined) videoURL.video = "bleachd-apparel";

    const urls = useRef(Object.keys(VideoDataCommercial));

    const currentVid = useRef(urls.current.indexOf(videoURL.video));
    const [ currentVideo, setCurrentVideo ] = useState(urls.current.indexOf(videoURL.video));
    
    const isScrollReady = useRef(true); 
    const [ changing, setChanging ] = useState(false);

    const [ fullscreen, setFullscreen ] = useState(null);
    const [ infoState, setInfoState ] = useState(null);
    // const [ thumbnailPlayState, setThumbnailPlayState ]  = useState(null);
    const infoRef = useRef(false);
    const thumbnailPlayState = useRef(true);
    const [ thumbnailCollection, setThumbnailCollection ] = useState(VideoDataCommercial[videoURL.video].thumbnails);
    const [ slideIndex, setSlideIndex ] = useState(0);
    const [slideAnimation, setSlideAnimation] = useState("pan-reverse 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
    const alternate = useRef(false);

    const initialized = useRef(false);

    const indexTimer = useRef(null);

    const base = useRef(true);

    const tooltipTimer = useRef(null);
    const scrollReadyTimer = useRef(null);
    const changingTimer = useRef(null);
    const switchTimer = useRef(null);

    const indexSelectors = [];

    for(let i = 0; i < urls.current.length; i++) {
        indexSelectors.push(
            <div key={i} className={`media-controls-indices__selector${currentVid.current === i ? " media-controls-indices__selector--active" : ""}`} onClick={currentVid.current !== i ? () => {handleChange(undefined, undefined, i)} : null}>
                <div className="media-controls-indices__tooltip">{VideoDataCommercial[urls.current[i]].title}</div>
                <div className="media-controls-indices__dot"></div>
            </div>
        );
    }

    // ---- FUNCTIONS ----

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    function handleChange(e, forwards, index) {

        if(e === undefined) {
            isScrollReady.current = true;
        }
        
        if (isScrollReady.current) {
            base.current = false;
            setSlideIndex(0);
            
            isScrollReady.current = false;
            setChanging(true);
            
            // check if input came from scroll event, onClick() will set e to undefined
            // if (e === undefined) {
                
            //     // setInfoState(false);
                if (index !== undefined) {
                    switchVideo(undefined, index);
                } else {
                    switchVideo(forwards);
                }

            // } else {
            //     if (e.wheelDelta > 0) {
            //         switchVideo(false)
            //     } else if (e.wheelDelta < 0) {
            //         switchVideo(true);
            //     }
            // }

            // TODO: fix these with a ref and cleanup function
            scrollReadyTimer.current = setTimeout(() => isScrollReady.current = true, 900);
            changingTimer.current = setTimeout(() => setChanging(false), 450);
        }
    }

    function switchVideo(forwards, index) {

        if (forwards === true) {
            if (currentVid.current < urls.current.length - 1) {
                switchTimer.current = setTimeout(() => {
                    currentVid.current++;
                    setCurrentVideo(currentVid.current);
                }, 225);
            } else {
                switchTimer.current = setTimeout(() => {
                    currentVid.current = 0;
                    setCurrentVideo(currentVid.current);
                }, 225);
            }
        } else if (forwards === false) {
            if (currentVid.current > 0) {
                switchTimer.current = setTimeout(() => {
                    currentVid.current--;
                    setCurrentVideo(currentVid.current);
                }, 225);
            } else {
                switchTimer.current = setTimeout(() => {
                    currentVid.current = urls.current.length - 1;
                    setCurrentVideo(currentVid.current);
                }, 225);
            }
            // (if forwards is undefined and context of function is specific index switching)
        } else {
            switchTimer.current = setTimeout(() => {
                currentVid.current = index;
                setCurrentVideo(currentVid.current);
            }, 225);
        }
    }

    function changeFullscreen(state) {

        // set a timeout to allow for closing animations to occur
        if (state === true) {
            scrollToTop();

            setFullscreen(state);
        } else {
            document.getElementsByClassName("exit-controls")[0].style.animation = "slide-down 0.3s forwards";
            // document.getElementsByClassName("shader-fullscreen")[0].style.opacity = 0;
            document.getElementsByClassName("media-video-desktop")[0].style.animation = "0.3s ease 0s 1 normal both running fade-out";

            setTimeout(() => {
                setFullscreen(state);
            }, 300);
        }
    }

    function changeInfoState(state) {
        setInfoState(state);
        infoRef.current = state;
    }

    function createIndexTimer() {
        indexTimer.current = setTimeout(() => {
            setSlideIndex(slideIndex < thumbnailCollection.length - 1 ? slideIndex + 1 : 0);
        }, 6500);
    }

    function initializeSlideAnimation() {
        clearTimeout(indexTimer.current);
        createIndexTimer();

        switch (slideIndex) {
            case 0:
                if (alternate.current) {
                    setSlideAnimation("pan-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                } else {
                    setSlideAnimation("pan 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                }

            case 1:
                if (alternate.current) {
                    setSlideAnimation("pan-reverse-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                } else {
                    setSlideAnimation("pan-reverse 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                }

            case 2:
                if (alternate.current) {
                    setSlideAnimation("pan-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                } else {
                    setSlideAnimation("pan 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                }

            case 3:
                if (alternate.current) {
                    setSlideAnimation("pan-reverse-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                } else {
                    setSlideAnimation("pan-reverse 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                    break;
                }
            default:
                break;
        }

        if (slideIndex === 3) {
            alternate.current = !alternate.current;
        }

        initialized.current = true;
    }


    // ---- EFFECT HOOKS ----

    // TODO: cleanup
    // TODO: disable on mobile
    useEffect(() => {

        function handleChange() {
            if (pageWidth >= 1024) {
                if (document.documentElement.scrollTop >= 500) {
                    clearTimeout(indexTimer.current);
                    thumbnailPlayState.current = false;
                } else {
                    if(indexTimer.current && !thumbnailPlayState.current) {
                        thumbnailPlayState.current = true;
                        indexTimer.current = setTimeout(() => {
                            setSlideIndex(slideIndex < thumbnailCollection.length - 1 ? slideIndex + 1 : 0);
                        }, 3250);
                    }
                }
            }
        }

        window.addEventListener("scroll", handleChange);

        return (() => {
            window.removeEventListener("scroll", handleChange);
        });

    }, [pageWidth, indexTimer.current]);


    useEffect(() => {
        
        function createIndexTimer() {
            indexTimer.current = setTimeout(() => {
                setSlideIndex(slideIndex < thumbnailCollection.length - 1 ? slideIndex + 1 : 0);
            }, 6500);
        }
        
        // reset the timer to prevent unintentional slide changes when switching video
        clearTimeout(indexTimer.current);
        createIndexTimer();

        if (initialized.current) {
            alternate.current = !alternate.current;

            if(alternate.current) {
                setSlideAnimation("pan-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
            } else {
                setSlideAnimation("pan 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
            }
        }

        window.history.replaceState(null, VideoDataCommercial[urls.current[currentVideo]].title, [`/commercial/${urls.current[currentVideo]}`]);

        setThumbnailCollection(VideoDataCommercial[urls.current[currentVideo]].thumbnails);

        return (() => {
            clearTimeout(indexTimer.current); 
        });
        
    }, [currentVideo, slideIndex, thumbnailCollection.length])



    useEffect(() => {

        const el = document.getElementsByClassName("media-thumbnail")[slideIndex];

        el.style.animation = slideAnimation;

    }, [slideAnimation, slideIndex])



    // TODO: use different event so it works with trackpad (EDIT: nevermind? kinda works?)
    // TODO: handle timing in here so trackpad doesn't call the function like 100 times
    useEffect(() => {

        // TODO: restructure to allow switching to specific index
        function handleChange(e, forwards) {

            // TODO: work on this logic
            if (isScrollReady.current && !fullscreen && !infoState) {
                base.current = false;
                setSlideIndex(0);

                isScrollReady.current = false;
                setChanging(true);

                // check if input came from scroll event, onClick() will set e to undefined
                // if (e !== undefined) {
                //     if (e.wheelDelta > 0) {
                //         switchVideo(false)
                //     } else if (e.wheelDelta < 0) {
                //         switchVideo(true);
                //     }
                // } else {
                //     switchVideo(forwards);
                // }

                scrollReadyTimer.current = setTimeout(() => isScrollReady.current = true, 900);
                changingTimer.current = setTimeout(() => setChanging(false), 450);
            }
        }

        function handleKeyDown(e) {
            if (!fullscreen) {
                if (e.code === 'ArrowLeft') {
                    handleChange(undefined, false);
                } else if (e.code === 'ArrowRight') {
                    handleChange(undefined, true);
                }
            }
        }

        // if an overlay is open, stop thumbnail timer
        if (fullscreen || infoState) {
            clearTimeout(indexTimer.current);
        // when closing the overlay, set short thumbnail timer
        // TODO: fix this logic
        } else if (fullscreen === false || infoState === false) {
            indexTimer.current = setTimeout(() => {
                setSlideIndex(slideIndex < thumbnailCollection.length - 1 ? slideIndex + 1 : 0);
            }, 3250);

            isScrollReady.current = true;
        }

        window.addEventListener('keydown', handleKeyDown)

        return (() => {
            clearTimeout(indexTimer.current);
            clearTimeout(scrollReadyTimer.current);
            clearTimeout(changingTimer.current);
            clearTimeout(switchTimer.current);

            window.removeEventListener("keydown", handleKeyDown);
        });

    }, [fullscreen, infoState]);



    useEffect(() => {
        if (initialized.current) {
            switch (slideIndex) {
                case 0:
                    if (alternate.current) {
                        setSlideAnimation("pan-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    } else {
                        setSlideAnimation("pan 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    }

                case 1:
                    if (alternate.current) {
                        setSlideAnimation("pan-reverse-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    } else {
                        setSlideAnimation("pan-reverse 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    }

                case 2:
                    if (alternate.current) {
                        setSlideAnimation("pan-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    } else {
                        setSlideAnimation("pan 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    }

                case 3:
                    if (alternate.current) {
                        setSlideAnimation("pan-reverse-2 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    } else {
                        setSlideAnimation("pan-reverse 6.5s cubic-bezier( 0.21, 0, 0.71, 1 ) 0s 1 normal both");
                        break;
                    }
                default:
                    break;
            }

            if (slideIndex === 3) {
                alternate.current = !alternate.current;
            }
        }

    }, [slideIndex])



    // initialize tooltip listeners
    useEffect(() => {

        for(let i = 0; i < indexSelectors.length; i++) {
            document.getElementsByClassName("media-controls-indices__selector")[i].addEventListener("mouseenter", () => {
                if (!document.getElementsByClassName("media-controls-indices__selector")[i].classList.contains("media-controls-indices__selector--active")) {
                    document.getElementsByClassName("media-controls-indices__tooltip")[i].style.display = "block";

                    tooltipTimer.current = setTimeout(() => {
                        document.getElementsByClassName("media-controls-indices__tooltip")[i].style.opacity = 1;
                    }, 300);
                }
            });

            document.getElementsByClassName("media-controls-indices__selector")[i].addEventListener("mouseleave", () => {
                clearTimeout(tooltipTimer.current);
                document.getElementsByClassName("media-controls-indices__tooltip")[i].style.opacity = 0;

                tooltipTimer.current = setTimeout(() => {
                    document.getElementsByClassName("media-controls-indices__tooltip")[i].style.display = "none";
                }, 300);
            });
        }

        return (() => {
            clearTimeout(indexTimer.current);
            clearTimeout(scrollReadyTimer.current);
            clearTimeout(changingTimer.current);
            clearTimeout(switchTimer.current);
            clearTimeout(tooltipTimer.current);
        });

    }, [indexSelectors.length])

    // VIDEO OPEN ANIMATIONS
    useEffect(() => {
        if(fullscreen) {
            document.getElementsByClassName("media-video-desktop")[0].style.animation = "0.3s ease 0s 1 normal both running fade-in";
            document.getElementsByClassName("shader-fullscreen")[0].style.opacity = 1;
        }
    }, [fullscreen])


    return (
        <Layout cn={"showcase"} current="commercial" footerColor="medium-dark">

            <div className={`media-viewer-container`}>
                <div className={`media-viewer${changing ? " media-viewer--changing" : ""}`}>

                    <div className="media-thumbnails">
                        {thumbnailCollection.map((url, index) => {
                            return(
                                <img onLoad={() => {initializeSlideAnimation()}} key={`${index}-${url}`} className={`media-thumbnail${slideIndex !== index ? " media-thumbnail--hidden" : ""}`} src={url} alt="" />
                            );
                        })}
                    </div>

                    <div className="media-viewer-info">
                        <h1 className="media-viewer-info__title">
                            {VideoDataCommercial[urls.current[currentVideo]].title}
                            {/* <svg onClick={() => {changeInfoState(true)}} viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29 10H27V8H29V10ZM29 12H27V18H29V12ZM28 5C23.59 5 20 8.59 20 13C20 17.41 23.59 21 28 21C32.41 21 36 17.41 36 13C36 8.59 32.41 5 28 5ZM28 3C33.523 3 38 7.477 38 13C38 18.523 33.523 23 28 23C22.477 23 18 18.523 18 13C18 7.477 22.477 3 28 3Z" fill="white" />
                            </svg> */}
                            <svg onClick={() => { changeInfoState(true) }} width="50" height="45" viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27 10H25V8H27V10ZM27 12H25V18H27V12ZM26 5C21.59 5 18 8.59 18 13C18 17.41 21.59 21 26 21C30.41 21 34 17.41 34 13C34 8.59 30.41 5 26 5ZM26 3C31.523 3 36 7.477 36 13C36 18.523 31.523 23 26 23C20.477 23 16 18.523 16 13C16 7.477 20.477 3 26 3Z" />
                            </svg>


                        </h1>
                        <hr />
                        <span>
                            {VideoDataCommercial[urls.current[currentVideo]].location}
                        </span>
                    </div>
                </div>

                <div onClick={() => handleChange(undefined, false)} className="media-controls-left">
                    <svg width="40" height="21" viewBox="0 0 40 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M40 10.5C40 10.8094 39.8495 11.1061 39.5816 11.3248C39.3137 11.5436 38.9504 11.6665 38.5715 11.6665L4.87938 11.6665L13.8702 19.0061C14.0031 19.1146 14.1084 19.2434 14.1803 19.3851C14.2522 19.5268 14.2892 19.6786 14.2892 19.832C14.2892 19.9854 14.2522 20.1373 14.1803 20.279C14.1084 20.4207 14.0031 20.5495 13.8702 20.6579C13.7374 20.7664 13.5798 20.8524 13.4062 20.9111C13.2327 20.9698 13.0467 21 12.8589 21C12.671 21 12.4851 20.9698 12.3115 20.9111C12.138 20.8524 11.9803 20.7664 11.8475 20.6579L0.419669 11.3259C0.28664 11.2175 0.181095 11.0888 0.109081 10.9471C0.0370676 10.8054 -9.0067e-07 10.6534 -9.14026e-07 10.5C-9.27383e-07 10.3466 0.0370675 10.1946 0.109081 10.0529C0.181095 9.9112 0.286639 9.78248 0.419669 9.67412L11.8475 0.342095C11.9803 0.233638 12.138 0.147605 12.3115 0.0889081C12.4851 0.0302113 12.671 2.70222e-06 12.8589 2.68573e-06C13.0467 2.66924e-06 13.2327 0.0302112 13.4062 0.088908C13.5798 0.147605 13.7374 0.233638 13.8702 0.342095C14.0031 0.450551 14.1084 0.579306 14.1803 0.721013C14.2522 0.862717 14.2892 1.0146 14.2892 1.16798C14.2892 1.32136 14.2522 1.47324 14.1803 1.61494C14.1084 1.75665 14.0031 1.88541 13.8702 1.99386L4.87938 9.3335L38.5715 9.3335C38.9504 9.3335 39.3137 9.45639 39.5816 9.67516C39.8495 9.89392 40 10.1906 40 10.5Z" />
                    </svg>
                </div>

                <div onClick={() => handleChange(undefined, true)} className="media-controls-right">
                    <svg width="40" height="21" viewBox="0 0 40 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.14026e-07 10.5C8.87095e-07 10.8094 0.150502 11.1061 0.418393 11.3248C0.686288 11.5436 1.04963 11.6665 1.42848 11.6665L35.1206 11.6665L26.1298 19.0061C25.9969 19.1146 25.8916 19.2434 25.8197 19.3851C25.7478 19.5268 25.7108 19.6786 25.7108 19.832C25.7108 19.9854 25.7478 20.1373 25.8197 20.279C25.8916 20.4207 25.9969 20.5495 26.1298 20.6579C26.2626 20.7664 26.4202 20.8524 26.5938 20.9111C26.7673 20.9698 26.9533 21 27.1411 21C27.329 21 27.5149 20.9698 27.6885 20.9111C27.862 20.8524 28.0197 20.7664 28.1525 20.6579L39.5803 11.3259C39.7134 11.2175 39.8189 11.0888 39.8909 10.9471C39.9629 10.8054 40 10.6534 40 10.5C40 10.3466 39.9629 10.1946 39.8909 10.0529C39.8189 9.9112 39.7134 9.78248 39.5803 9.67412L28.1525 0.342095C28.0197 0.233638 27.862 0.147605 27.6885 0.0889081C27.5149 0.0302113 27.329 2.70222e-06 27.1411 2.68573e-06C26.9533 2.66924e-06 26.7673 0.0302112 26.5938 0.088908C26.4202 0.147605 26.2626 0.233638 26.1298 0.342095C25.9969 0.450551 25.8916 0.579306 25.8197 0.721013C25.7478 0.862717 25.7108 1.0146 25.7108 1.16798C25.7108 1.32136 25.7478 1.47324 25.8197 1.61494C25.8916 1.75665 25.9969 1.88541 26.1298 1.99386L35.1206 9.3335L1.42848 9.3335C1.04963 9.3335 0.686288 9.45639 0.418393 9.67516C0.150502 9.89392 9.40958e-07 10.1906 9.14026e-07 10.5Z" />
                    </svg>
                </div>

                <div className="media-controls-indices">
                    {indexSelectors}
                </div>

                {/* <DesktopVideo title={VideoDataCommercial[urls.current[currentVideo]].title} src={VideoDataCommercial[urls.current[currentVideo]].link} fullscreen={fullscreen} changeFullscreen={changeFullscreen}></DesktopVideo>
                <DesktopVideoInfo title={VideoDataCommercial[urls.current[currentVideo]].title} description={VideoDataCommercial[urls.current[currentVideo]].description} location={VideoDataCommercial[urls.current[currentVideo]].location} date={VideoDataCommercial[urls.current[currentVideo]].date} infoState={infoState} closeInfo={() => {changeInfoState(false)}}></DesktopVideoInfo>
                <DesktopMediaAbout dataSet={VideoDataCommercial} index={currentVideo} title="COMMERCIAL" context="commercial" setVideoIndex={handleChange} desc={BiographyData.commercialParagraph} descOffers={BiographyData.commercialParagraphOffers}></DesktopMediaAbout> */}

                <div className="media-viewer-cta" onClick={() => {changeFullscreen(true)}}>
                    WATCH VIDEO
                </div>

                <span className="media-viewer-learn-more" onClick={() => {window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" }); }}>LEARN MORE
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.40013 0.767385C0.492213 0.668062 0.602959 0.587852 0.726044 0.531334C0.84913 0.474815 0.982143 0.443097 1.11749 0.437989C1.25283 0.432882 1.38786 0.454486 1.51485 0.501567C1.64185 0.548648 1.75832 0.620285 1.85763 0.712385L7.00013 5.56201L12.1426 0.712385C12.2419 0.620296 12.3584 0.548669 12.4854 0.501595C12.6124 0.45452 12.7474 0.43292 12.8828 0.438027C13.0181 0.443135 13.1511 0.474849 13.2742 0.531361C13.3973 0.587872 13.508 0.668073 13.6001 0.767385C13.6922 0.866697 13.7638 0.983176 13.8109 1.11017C13.858 1.23716 13.8796 1.37218 13.8745 1.50753C13.8694 1.64287 13.8377 1.77588 13.7812 1.89896C13.7246 2.02205 13.6444 2.1328 13.5451 2.22489L7.70138 7.72489C7.51069 7.90174 7.26021 8.00001 7.00013 8.00001C6.74005 8.00001 6.48957 7.90174 6.29888 7.72489L0.45513 2.22489C0.355807 2.1328 0.275596 2.02206 0.219078 1.89897C0.162559 1.77589 0.130841 1.64287 0.125733 1.50753C0.120626 1.37218 0.14223 1.23716 0.189311 1.11016C0.236393 0.983168 0.308029 0.866692 0.40013 0.767385V0.767385Z" />
                    </svg>
                </span>
            </div>
            <DesktopVideo title={VideoDataCommercial[urls.current[currentVideo]].title} src={VideoDataCommercial[urls.current[currentVideo]].link} fullscreen={fullscreen} changeFullscreen={changeFullscreen}></DesktopVideo>
            <DesktopVideoInfo title={VideoDataCommercial[urls.current[currentVideo]].title} description={VideoDataCommercial[urls.current[currentVideo]].description} location={VideoDataCommercial[urls.current[currentVideo]].location} date={VideoDataCommercial[urls.current[currentVideo]].date} infoState={infoState} closeInfo={() => { changeInfoState(false) }}></DesktopVideoInfo>
            <DesktopMediaAbout dataSet={VideoDataCommercial} index={currentVideo} title="PROMOTE YOUR BUSINESS OR EVENT" context="commercial" setVideoIndex={handleChange} desc={BiographyData.commercialParagraph} descOffers={BiographyData.commercialParagraphOffers}></DesktopMediaAbout>
        </Layout>
    )
}

export default CommercialDesktopViewer
