import { Link } from "react-router-dom";

import "./music-video-item.scss";

function MusicVideo(props) {

    // let { url } = useRouteMatch();

    return (
        <li className="music-video-item">
            <Link className="video-contents__button" role="button" to={`/music-videos/${props.url}`}></Link>
            <img className="video-thumbnail" src={props.video} alt=""/>
            <div className="video-contents">
                <h2 className="video-contents__title">{props.title}</h2>
                <p className="video-contents__artist">{props.artist}</p>
            </div>
        </li>
    );
}

export default MusicVideo;