import { useEffect } from "react";

import { Link } from "react-router-dom";

import { Layout, BiographyData } from "../../exports";

import "./bio.scss";

function Bio() {

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])

    return (
        <Layout cn={"bio"} current="bio">
            <div className="bio">
                <div className="bio-text">
                    <div className="bio-text-container">
                        <h1 className="bio-text__header">WHO WE ARE</h1>
                        <p className="bio-text__paragraph">{BiographyData.bioParagraph1}</p>
                    </div>
                    <div className="bio-text-container">
                        <h1 className="bio-text__header">OUR STORY</h1>
                        <p className="bio-text__paragraph">{BiographyData.bioParagraph2}</p>
                    </div>
                    <div className="bio-text-container">
                        <h1 className="bio-text__header">THE MISSION</h1>
                        <p className="bio-text__paragraph">{BiographyData.bioParagraph3}</p>
                    </div>
                </div>

                <div className="bio-cta">
                    <Link to="/contact">BOOKING &amp; INQUIRIES
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.37833e-06 6C1.39392e-06 6.17679 0.0714888 6.34633 0.198738 6.47134C0.325986 6.59635 0.498573 6.66657 0.678529 6.66657L16.6823 6.66657L12.4116 10.8607C12.3486 10.9226 12.2985 10.9962 12.2644 11.0772C12.2302 11.1582 12.2126 11.2449 12.2126 11.3326C12.2126 11.4202 12.2302 11.507 12.2644 11.588C12.2985 11.669 12.3486 11.7425 12.4116 11.8045C12.4747 11.8665 12.5496 11.9157 12.632 11.9492C12.7145 11.9827 12.8028 12 12.892 12C12.9813 12 13.0696 11.9827 13.152 11.9492C13.2345 11.9157 13.3093 11.8665 13.3724 11.8045L18.8007 6.47193C18.8638 6.41001 18.914 6.33646 18.9482 6.25547C18.9824 6.17449 19 6.08768 19 6C19 5.91232 18.9824 5.82551 18.9482 5.74452C18.914 5.66354 18.8638 5.58998 18.8007 5.52807L13.3724 0.19548C13.3093 0.133505 13.2345 0.0843435 13.152 0.0508027C13.0696 0.017262 12.9813 -1.38117e-06 12.892 -1.37337e-06C12.8028 -1.36557e-06 12.7145 0.0172621 12.632 0.0508028C12.5496 0.0843435 12.4747 0.133505 12.4116 0.19548C12.3485 0.257455 12.2985 0.33103 12.2644 0.412005C12.2302 0.49298 12.2126 0.579768 12.2126 0.667414C12.2126 0.755061 12.2302 0.841848 12.2644 0.922823C12.2985 1.0038 12.3485 1.07737 12.4116 1.13935L16.6823 5.33343L0.678529 5.33343C0.498573 5.33343 0.325986 5.40366 0.198738 5.52866C0.0714888 5.65367 1.36274e-06 5.82321 1.37833e-06 6Z" fill="#0F0F0F" />
                        </svg>
                    </Link>
                </div>

                <div className="bio-image">
                    <div className="bio-image__overlay"></div>
                </div>

            </div>
        </Layout>
    )
}

export default Bio;
