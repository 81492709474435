const data = {
    weddingsHomeSectionText: 
    `Capture the unforgettable moments of your big day with breathtaking wedding videos and photography.
    
    Our services turn memories into a keepsake that will last a lifetime.`,

    photos: [
        "/resources/images/events/events9.jpg",
        "/resources/images/events/events12.jpg",
        "/resources/images/events/events11.jpg",
        "/resources/images/events/events8.jpg",
    ]
}

module.exports = data;