import { Button } from "../../exports";

import "./info-section.scss";

function InfoSection(props) {

    return (
        props.mobile ? (
            <div className="info-section">
                <h2 className="info-section-header">{props.headerText}</h2>

                <div className="info-section-img">
                    <img src={props.imageSrc} alt="" />
                </div>

                <div className="info-section-text">
                    <div className="info-section-text__content">
                        {props.children}
                    </div>
                    <Button buttonColor="primary" buttonOutlined buttonDest={props.buttonDest}>{props.buttonText}</Button>
                </div>
            </div>
        ) :
        (props.right ? (
            <div className="info-section info-section--right">
                <div className="info-section-img">
                    <img src={props.imageSrc} alt="" />
                </div>

                <div className="info-section-text">
                    <h2 className="info-section-text__header">{props.headerText}</h2>
                    <div className="info-section-text__content">
                        {props.children}
                    </div>
                    <Button buttonColor="primary" buttonOutlined buttonDest={props.buttonDest}>{props.buttonText}</Button>
                </div>
            </div>
        ) : (
            <div className="info-section"> 
                <div className="info-section-text">
                    <h2 className="info-section-text__header">{props.headerText}</h2>
                    <div className="info-section-text__content">
                        {props.children}
                    </div>
                        <Button buttonColor="primary" buttonOutlined buttonDest={props.buttonDest}>{props.buttonText}</Button>
                </div>

                <div className="info-section-img">
                    <img src={props.imageSrc} alt="" />
                </div>
            </div>
        )));
}

export default InfoSection;