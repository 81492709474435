import { useEffect, useState } from "react";
import { Button } from "../../exports";

import "./photography-card.scss";

function PhotographyCard(props) {

    const [currentActive, setCurrentActive] = useState(false)
    
    useEffect(() => {
        
        if (currentActive) {
            let len = document.getElementsByClassName("photography-card-container").length;
            console.log(len)

            for(let i = 0; i < len; i++) {
                let e = document.getElementsByClassName("photography-card-container")[i];

                    e.classList.add("photography-card-container--inactive")
            }
        } else {
            let len = document.getElementsByClassName("photography-card-container").length;
            let activeSet = false;

            for (let i = 0; i < len; i++) {
                let e = document.getElementsByClassName("photography-card-container")[i];

                if (e.classList.contains("photography-card-container--active")) {
                    activeSet = true;
                }
            }

            if(!activeSet) {
                for (let i = 0; i < len; i++) {
                    let e = document.getElementsByClassName("photography-card-container")[i];

                    if (e.classList.contains("photography-card-container--inactive")) {
                        e.classList.remove("photography-card-container--inactive")
                    }
                }
            }
        }
    }, [currentActive])

    function toggleActive(e) {

        let selector = e.target.closest(".photography-card-container");

        if (selector.classList.contains("photography-card-container--active")) {
            selector.classList.remove("photography-card-container--active");
        } else {
            document.querySelectorAll(".photography-card-container--active").forEach(item => {
                item.classList.remove("photography-card-container--active");
            });
            selector.classList.add("photography-card-container--active");
        }
    }

    return (
        props.mobile ? (
            <div className={`photography-card-container photography-card-container--mobile${currentActive ? " photography-card-container--active" : ""}`} onClick={e => { toggleActive(e) }}>
                <div className="photography-card">
                    <div className="photography-card-shader" />
                    <div className="photography-card-text">
                        <span className="photography-card-text__title">{props.title}</span>
                        <hr />
                        <p className="photography-card-text__desc">{props.children}</p>
                        <Button buttonColor="white" buttonDest={props.url}>VIEW PHOTOS</Button>
                    </div>

                    <img className="photography-card-image" src={props.thumbnail} alt="" />
                </div>

                <span className={`photography-card-title${currentActive ? " photography-card-title--inactive" : ""}`}>{props.title}</span>
            </div>
        ) : (
            <a href={props.url} className={`photography-card-container${currentActive ? " photography-card-container--active" : ""}`} onMouseEnter={() => {setCurrentActive(true)}} onMouseLeave={() => {setCurrentActive(false)}}>
                <div className="photography-card">
                    <div className="photography-card-shader" />
                    <div className="photography-card-text">
                        <span className="photography-card-text__title">{props.title}</span>
                        <hr />
                        <p className="photography-card-text__desc">{props.children}</p>
                    </div>

                    <img className="photography-card-image" src={props.thumbnail} alt="" />
                </div>

                <span className={`photography-card-title${currentActive ? " photography-card-title--inactive" : ""}`}>{props.title}</span>
            </a>
        ));
}

export default PhotographyCard;