import { createSlice } from '@reduxjs/toolkit'

export const pageWidthReducer = createSlice({
    name: 'pageWidth',
    initialState: {
        value: 0
    },
    reducers: {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
        set: (state, action) => {
            state.value = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { set } = pageWidthReducer.actions

export default pageWidthReducer.reducer