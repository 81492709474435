const VideoDataCommercial = {

    "bleachd-apparel": {
        thumbnails: 
        [
            "/resources/thumbnails/commercial/bleachd-apparel/bleachd-apparel-thumbnail-1.jpg",
            "/resources/thumbnails/commercial/bleachd-apparel/bleachd-apparel-thumbnail-2.jpg",
            "/resources/thumbnails/commercial/bleachd-apparel/bleachd-apparel-thumbnail-3.jpg",
            "/resources/thumbnails/commercial/bleachd-apparel/bleachd-apparel-thumbnail-4.jpg",
        ],

        preview: "/resources/thumbnails-small/bleachd-apparel-thumbnail-1.jpg",

        title: "BLEACH'D APPAREL",

        description: "Short promotional video made for the Bleach'd Apparel April 2020 drop.",

        location: "State College, PA",

        date: "April 2020",

        link: "https://player.vimeo.com/video/584344864?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=30c414573e",
    },

    "three-beards": {
        thumbnails:
            [
                "/resources/thumbnails/commercial/three-beards/three-beards-thumbnail-1.jpg",
                "/resources/thumbnails/commercial/three-beards/three-beards-thumbnail-2.jpg",
                "/resources/thumbnails/commercial/three-beards/three-beards-thumbnail-3.jpg",
                "/resources/thumbnails/commercial/three-beards/three-beards-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails/commercial/three-beards/three-beards-thumbnail-1.jpg",

        title: "THREE BEARDS BREWING",

        description: "Short promotional video filmed for Three Beards Brewing in Sunbury, PA.",

        location: "Sunbury, PA",

        date: "May 2022",

        link: "https://player.vimeo.com/video/787636964?h=ffd8467408&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },

    "emo-night": {
        thumbnails:
            [
                "/resources/thumbnails/commercial/emo-night/emo-night-thumbnail-1.jpg",
                "/resources/thumbnails/commercial/emo-night/emo-night-thumbnail-2.jpg",
                "/resources/thumbnails/commercial/emo-night/emo-night-thumbnail-3.jpg",
                "/resources/thumbnails/commercial/emo-night/emo-night-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails/commercial/emo-night/emo-night-thumbnail-1.jpg",

        title: "EMO NIGHT",

        description: "Video created for Screaming Infidelities' Emo Night.",

        location: "Williamsport, PA",

        date: "November 2022",

        link: "https://player.vimeo.com/video/787638113?h=8392a93886&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },

    "evolution-clothing": {
        thumbnails:
            [
                "/resources/thumbnails/commercial/evolution-clothing/evolution-clothing-thumbnail-1.jpg",
                "/resources/thumbnails/commercial/evolution-clothing/evolution-clothing-thumbnail-2.jpg",
                "/resources/thumbnails/commercial/evolution-clothing/evolution-clothing-thumbnail-3.jpg",
                "/resources/thumbnails/commercial/evolution-clothing/evolution-clothing-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails/commercial/evolution-clothing/evolution-clothing-thumbnail-1.jpg",

        title: "EVOLUTION CLOTHING",

        description: "Video created for the apparel brand Evolution.",

        location: "Sunbury, PA",

        date: "November 2022",

        link: "https://player.vimeo.com/video/790576881?h=2e7bb541be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },

    "invictum-roadhouse": {
        thumbnails: 
        [
            "/resources/thumbnails/commercial/invictum-roadhouse/invictum-roadhouse-thumbnail-1.jpg",
            "/resources/thumbnails/commercial/invictum-roadhouse/invictum-roadhouse-thumbnail-2.jpg",
            "/resources/thumbnails/commercial/invictum-roadhouse/invictum-roadhouse-thumbnail-3.jpg",
            "/resources/thumbnails/commercial/invictum-roadhouse/invictum-roadhouse-thumbnail-4.jpg",
        ],

        preview: "/resources/thumbnails-small/invictum-roadhouse-thumbnail-1.jpg",

        title: "INVICTUM ROADHOUSE",

        description: "Video produced for Invictum of one of their concerts, promoting their upcoming event.",

        location: "Sunbury, PA",

        date: "October 2019",

        link: "https://player.vimeo.com/video/584344477?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=9a64ee8fc9",
    },

    "shockwave-softball": {
        thumbnails:
            [
                "/resources/thumbnails/commercial/shockwave-softball/shockwave-softball-thumbnail-1.jpg",
                "/resources/thumbnails/commercial/shockwave-softball/shockwave-softball-thumbnail-2.jpg",
                "/resources/thumbnails/commercial/shockwave-softball/shockwave-softball-thumbnail-3.jpg",
                "/resources/thumbnails/commercial/shockwave-softball/shockwave-softball-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails-small/shockwave-softball-thumbnail-1.jpg",

        title: "SHOCKWAVE SOFTBALL",

        description: "Short video made for the NEPA Shockwave Fastpitch Softball team to promote their open tryouts.",

        location: "Catawissa, PA",

        date: "June 2021",

        link: "https://player.vimeo.com/video/586001566?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=10a7356322",
    },

    
    "ghost-town": {
        thumbnails: 
        [
            "/resources/thumbnails/commercial/ghost-town/ghost-town-thumbnail-1.jpg",
            "/resources/thumbnails/commercial/ghost-town/ghost-town-thumbnail-2.jpg",
            "/resources/thumbnails/commercial/ghost-town/ghost-town-thumbnail-3.jpg",
            "/resources/thumbnails/commercial/ghost-town/ghost-town-thumbnail-4.jpg",
        ],

        preview: "/resources/thumbnails-small/ghost-town-thumbnail-1.jpg",
        
        title: "GHOST TOWN",
        
        description: "Live action fictional short film produced for Nick Kuzo, depicting military conflict in an Eastern Europe setting.",
        
        location: "Nanticoke, PA",
        
        date: "January 2021",
        
        link: "https://player.vimeo.com/video/585996065?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=4cedc9cba5",
    },

    "buildkitboards": {
        thumbnails:
            [
                "/resources/thumbnails/commercial/buildkitboards/buildkitboards-thumbnail-1.jpg",
                "/resources/thumbnails/commercial/buildkitboards/buildkitboards-thumbnail-2.jpg",
                "/resources/thumbnails/commercial/buildkitboards/buildkitboards-thumbnail-3.jpg",
                "/resources/thumbnails/commercial/buildkitboards/buildkitboards-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails-small/buildkitboards-thumbnail-1.jpg",

        title: "BUILD KIT BOARDS",

        description: "Promotional video for Build Kit Boards, showcasing the features of their DIY electric skateboard kit.",

        location: "Bloomsburg, PA",

        date: "November 2019",

        link: "https://player.vimeo.com/video/610878197?h=60a72f827e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    
    "double-platinum": {
        thumbnails:
            [
                "/resources/thumbnails/commercial/double-platinum/double-platinum-thumbnail-1.jpg",
                "/resources/thumbnails/commercial/double-platinum/double-platinum-thumbnail-2.jpg",
                "/resources/thumbnails/commercial/double-platinum/double-platinum-thumbnail-3.jpg",
                "/resources/thumbnails/commercial/double-platinum/double-platinum-thumbnail-4.jpg",
            ],

        preview: "/resources/thumbnails-small/double-platinum-thumbnail-1.jpg",

        title: "DOUBLE PLATINUM",

        description: "Advertisement made for the Double Platinum barber shop located in Reading.",

        location: "Reading, PA",

        date: "June 2019",

        link: "https://player.vimeo.com/video/594806227?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=c5b355dc13",
    },

    // "kroh-wedding": {
    //     thumbnails:
    //         [
    //             "/resources/thumbnails/commercial/kroh-wedding/kroh-wedding-thumbnail-1.jpg",
    //             "/resources/thumbnails/commercial/kroh-wedding/kroh-wedding-thumbnail-2.jpg",
    //             "/resources/thumbnails/commercial/kroh-wedding/kroh-wedding-thumbnail-3.jpg",
    //             "/resources/thumbnails/commercial/kroh-wedding/kroh-wedding-thumbnail-4.jpg",
    //         ],

    //     preview: "/resources/thumbnails-small/kroh-wedding-thumbnail-1.jpg",

    //     title: "KROH WEDDING",

    //     description: "Video produced for Aaron and Brooke's wedding ceremony in Reynoldsville.",

    //     location: "Reynoldsville, PA",

    //     date: "October 2020",

    //     link: "https://player.vimeo.com/video/584980350?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=3ce2db6d57",
    // },

    "kayaking-adventure": {
        thumbnails: 
        [
            "/resources/thumbnails/commercial/kayaking-adventure/kayaking-adventure-thumbnail-1.jpg",
            "/resources/thumbnails/commercial/kayaking-adventure/kayaking-adventure-thumbnail-2.jpg",
            "/resources/thumbnails/commercial/kayaking-adventure/kayaking-adventure-thumbnail-3.jpg",
            "/resources/thumbnails/commercial/kayaking-adventure/kayaking-adventure-thumbnail-4.jpg",
        ],

        preview: "/resources/thumbnails-small/kayaking-adventure-thumbnail-1.jpg",

        title: "KAYAKING ADVENTURE",

        description: "Enjoy some beautiful drone footage from a day kayaking at Bear Gap.",

        location: "Bear Gap, PA",

        date: "August 2019",

        link: "https://player.vimeo.com/video/586002542?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=3750c5b2d4",
    },

    // "bloom-nights": {
    //     thumbnails: 
    //     [
    //         "/resources/thumbnails/commercial/bloom-nights/bloom-nights-thumbnail-1.jpg",
    //         "/resources/thumbnails/commercial/bloom-nights/bloom-nights-thumbnail-2.jpg",
    //         "/resources/thumbnails/commercial/bloom-nights/bloom-nights-thumbnail-3.jpg",
    //         "/resources/thumbnails/commercial/bloom-nights/bloom-nights-thumbnail-4.jpg",
    //     ],

    //     preview: "/resources/thumbnails-small/bloom-nights-thumbnail-1.jpg",

    //     title: "BLOOM NIGHTS",

    //     description: "Aesthetic shots of the Bloomsburg University campus.",

    //     location: "Bloomsburg, PA",

    //     date: "April 2019",

    //     link: "https://player.vimeo.com/video/584345598?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=bf3be8bf46",
    // },
}

module.exports = VideoDataCommercial;