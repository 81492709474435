import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, DesktopVideo, HomeData, InfoSection, Layout, MobileVideo, PhotoData, PhotographyCard, WeddingsData } from "../../exports";

import "./home.scss";

function useWindowSize() {
    const [ size, setSize ] = useState([0, 0]);
    
    function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
    }

    useEffect(() => {
        
        window.addEventListener('resize', updateSize);
        
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])
    
    return size;
}


function Home() {

    function fadeInVideo() {
        document.getElementById("home-background-video").style.animation = "fade-in 0.4s linear forwards";
    }

    function changeFullscreen(state) {

        // set a timeout to allow for closing animations to occur
        if (state === true) {
            setFullscreen(state);
        } else {
            document.getElementsByClassName("exit-controls")[0].style.animation = "slide-down 0.3s forwards";
            document.getElementsByClassName("media-video-desktop")[0].style.animation = "0.3s ease 0s 1 normal both running fade-out";

            setFullscreen(state);
        }
    }

    function changeFullscreenMobile(state) {

        // set a timeout to allow for closing animations to occur
        if (state === true) {
            setFullscreenMobile(state);
            document.getElementsByClassName("media-video-mobile")[0].style.animation = "0.3s ease 0s 1 normal both running fade-in";
        } else {
            document.getElementsByClassName("exit-controls")[0].style.animation = "slide-down 0.3s forwards";
            document.getElementsByClassName("media-video-mobile")[0].style.animation = "0.3s ease 0s 1 normal both running fade-out";

            setFullscreenMobile(state);
        }
    }

    const [ fullscreen, setFullscreen ] = useState(null)
    const [ fullscreenMobile, setFullscreenMobile ] = useState(null)
    const [ muted, setMuted ] = useState(true);
    const [ mutedMobile, setMutedMobile ] = useState(true);
    const [pageWidth, setPageWidth] = useState(0);


    const [width, height] = useWindowSize();

    // TODO: adjust layout based on innerHeight to avoid issues with address and bottom bars
    // TODO: tablet layout
    // TODO: show reel @ "teamapollovideos.com/reel". do this with react-router so it's the home component but with video state

    useEffect(() => {
        const vid = document.getElementById("home-background-video");

        if(fullscreen) {
            vid.pause();
        } else if(fullscreen === false) {
            vid.play();
        }

    }, [fullscreen])

    useEffect(() => {
        const vid = document.getElementById("home-background-video-mobile");

        if (fullscreen) {
            vid.pause();
        } else if (fullscreen === false) {
            vid.play();
        }

    }, [fullscreenMobile, fullscreen])

    useEffect(() => {
        document.getElementById("home-background-video").muted = muted;
    }, [muted])

    useEffect(() => {
        document.getElementById("home-background-video-mobile").muted = mutedMobile;
    }, [mutedMobile])

    useEffect(() => {
        setPageWidth(document.documentElement.clientWidth)
        document.getElementById("home-background-video-mobile").muted = true;
        document.getElementById("home-background-video-mobile").play();
    }, [])

    function AboutSection(props) {
        return(
            <div className="about-section">
                <InfoSection mobile={props.mobile} headerText="TAKE YOUR IDEAS TO THE NEXT LEVEL" buttonText="BOOKING&nbsp;&&nbsp;INQUIRIES" buttonDest="/contact" imageSrc={HomeData.generalInfoImg}>
                    <p>{HomeData.generalInfoText1}</p>
                    {/* <p>{HomeData.generalInfoText2}</p> */}
                </InfoSection>
                <InfoSection mobile={props.mobile} right={!props.mobile} headerText="PROMOTE YOUR BUSINESS" buttonText="COMMERCIAL&nbsp;GALLERY" buttonDest="/commercial" imageSrc={HomeData.commercialInfoImg}>
                    <p>{HomeData.commercialInfoText}</p>
                </InfoSection>
                <InfoSection mobile={props.mobile} headerText="COMPLIMENT YOUR MUSIC" buttonText="MUSIC&nbsp;VIDEOS" buttonDest="/music-videos" imageSrc={HomeData.musicVideosInfoImg}>
                    <p>{HomeData.musicVideosInfoText}</p>
                </InfoSection>
            </div>
        )
    }

    function WeddingsSection() {
        return(
            <div className="weddings-section">
                <div className="weddings-section-text">
                    <p className="weddings-section-text-subheader">TEAM APOLLO</p>
                    <h2 className="weddings-section-text-header">Weddings</h2>
                    <p>{WeddingsData.weddingsHomeSectionText}</p>
                    <div className="weddings-section-text-cta">
                        <Link to="/weddings">WEDDINGS&nbsp;PAGE
                            <svg width="38" height="20" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15 4C15 3.88214 14.9436 3.76911 14.8431 3.68577C14.7426 3.60244 14.6064 3.55562 14.4643 3.55562L1.82977 3.55562L5.20134 0.759566C5.25115 0.718249 5.29065 0.669199 5.31761 0.615216C5.34456 0.561233 5.35844 0.503374 5.35844 0.444943C5.35844 0.386513 5.34456 0.328654 5.31761 0.274671C5.29065 0.220688 5.25115 0.171638 5.20134 0.130321C5.15154 0.089004 5.09241 0.0562298 5.02733 0.0338693C4.96226 0.0115088 4.89251 0 4.82208 0C4.75164 0 4.6819 0.0115088 4.61682 0.0338693C4.55175 0.0562298 4.49262 0.089004 4.44282 0.130321L0.157376 3.68538C0.10749 3.72666 0.0679111 3.77569 0.0409059 3.82968C0.0139007 3.88367 2.94372e-09 3.94155 2.98738e-09 4C3.03103e-09 4.05845 0.0139007 4.11633 0.0409059 4.17032C0.0679111 4.2243 0.10749 4.27334 0.157376 4.31462L4.44282 7.86968C4.49262 7.911 4.55175 7.94377 4.61682 7.96613C4.6819 7.98849 4.75164 8 4.82208 8C4.89251 8 4.96226 7.98849 5.02733 7.96613C5.09241 7.94377 5.15154 7.911 5.20134 7.86968C5.25115 7.82836 5.29065 7.77931 5.31761 7.72533C5.34456 7.67135 5.35844 7.61349 5.35844 7.55506C5.35844 7.49662 5.34456 7.43877 5.31761 7.38478C5.29065 7.3308 5.25115 7.28175 5.20134 7.24043L1.82977 4.44438L14.4643 4.44438C14.6064 4.44438 14.7426 4.39756 14.8431 4.31423C14.9436 4.23089 15 4.11786 15 4Z" />
                            </svg>
                        </Link>
                    </div>
                </div>

                <div className="weddings-section-images">
                    {WeddingsData.photos.map((object, i) => <img className="weddings-section-images__image" src={object} key={i} alt="" />)}
                </div>
            </div>
        )
    }

    function PhotographySection(props) {
        return(
            props.mobile ? (
                <div className="photography-section photography-section--mobile">
                    <h2>PHOTOGRAPHY FOR ANY OCCASION</h2>

                    <div className="photography-section-cards">
                        <PhotographyCard mobile={props.mobile} title="Commercial" url="/photography/commercial" thumbnail="resources/thumbnails/photography/commercial.jpg">
                            {PhotoData.commercial.description}
                        </PhotographyCard>

                        <PhotographyCard mobile={props.mobile} title="Events" url="/photography/events" thumbnail="resources/thumbnails/photography/events.jpg">
                            {PhotoData.events.description}
                        </PhotographyCard>

                        <PhotographyCard mobile={props.mobile} title="Portraits" url="/photography/portraits" thumbnail="resources/thumbnails/photography/portraits.jpg">
                            {PhotoData.portraits.description}
                        </PhotographyCard>

                        <PhotographyCard mobile={props.mobile} title="Real Estate" url="/photography/real-estate" thumbnail="resources/thumbnails/photography/real-estate.jpg">
                            {PhotoData["real-estate"].description}
                        </PhotographyCard>
                    </div>

                    <Button buttonColor="white" buttonDest="/photography">SEE ALL</Button>
                </div>
            ) : (
                <div className="photography-section">
                    <h2>PHOTOGRAPHY FOR ANY OCCASION</h2>

                    <div className="photography-section-cards">
                        <PhotographyCard mobile={props.mobile} title="Commercial" url="/photography/commercial" thumbnail="resources/images/commercial/_thumbnail.jpg">
                                {PhotoData.commercial.description}
                        </PhotographyCard>

                        <PhotographyCard mobile={props.mobile} title="Events" url="/photography/events" thumbnail="resources/images/events/events18.jpg">
                                {PhotoData.events.description}
                        </PhotographyCard>

                        <PhotographyCard mobile={props.mobile} title="Portraits" url="/photography/portraits" thumbnail="resources/images/portraits/portrait1.jpg">
                                {PhotoData.portraits.description}
                        </PhotographyCard>

                        <PhotographyCard mobile={props.mobile} title="Real Estate" url="/photography/real-estate" thumbnail="resources/images/real-estate/_thumbnail.jpg">
                                {PhotoData["real-estate"].description}
                        </PhotographyCard>
                    </div>

                    <Button buttonColor="white" buttonDest="/photography">SEE ALL</Button>
                </div>
            )
        )
    }

    function ContactSection() {
        return(
            <div className="contact-section">
                <h2 className="contact-section-header">Ready to Work With Us?</h2>
                <p className="contact-section-text">We would love to help you express your creative vision. Contact us today to get started on your next big project.</p>
                
                <div className="contact-section-buttons">
                    <Button buttonColor="primary" buttonDest="/contact" >BOOKING&nbsp;&&nbsp;INQUIRIES</Button>
                </div>

                <img src="/resources/graphics/spaceman_dark.png" alt="Spaceman logo" />
            </div>
        )
    }

    return (
        <Layout cn={"home"} footerColor="medium-dark" menuColor="medium-dark">
            {/* MOBILE VEIW */}
            
            <div className="home-mobile-view">

                <div className="home-mobile-hero">
                    <video src="resources/videos/video-reel-mobile.mp4" muted autoPlay loop playsInline disablePictureInPicture id="home-background-video-mobile" className="home-background-video-mobile">
                    </video>

                    <div className="home-buttons">

                        <div className="reel-cta" onClick={() => { changeFullscreenMobile(true); setMutedMobile(true) }}>
                            <span>WATCH&nbsp;PRODUCTION&nbsp;REEL</span>
                        </div>

                        <Link to="/contact">BOOKING&nbsp;&amp;&nbsp;INQUIRIES
                            <svg width="38" height="20" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15 4C15 3.88214 14.9436 3.76911 14.8431 3.68577C14.7426 3.60244 14.6064 3.55562 14.4643 3.55562L1.82977 3.55562L5.20134 0.759566C5.25115 0.718249 5.29065 0.669199 5.31761 0.615216C5.34456 0.561233 5.35844 0.503374 5.35844 0.444943C5.35844 0.386513 5.34456 0.328654 5.31761 0.274671C5.29065 0.220688 5.25115 0.171638 5.20134 0.130321C5.15154 0.089004 5.09241 0.0562298 5.02733 0.0338693C4.96226 0.0115088 4.89251 0 4.82208 0C4.75164 0 4.6819 0.0115088 4.61682 0.0338693C4.55175 0.0562298 4.49262 0.089004 4.44282 0.130321L0.157376 3.68538C0.10749 3.72666 0.0679111 3.77569 0.0409059 3.82968C0.0139007 3.88367 2.94372e-09 3.94155 2.98738e-09 4C3.03103e-09 4.05845 0.0139007 4.11633 0.0409059 4.17032C0.0679111 4.2243 0.10749 4.27334 0.157376 4.31462L4.44282 7.86968C4.49262 7.911 4.55175 7.94377 4.61682 7.96613C4.6819 7.98849 4.75164 8 4.82208 8C4.89251 8 4.96226 7.98849 5.02733 7.96613C5.09241 7.94377 5.15154 7.911 5.20134 7.86968C5.25115 7.82836 5.29065 7.77931 5.31761 7.72533C5.34456 7.67135 5.35844 7.61349 5.35844 7.55506C5.35844 7.49662 5.34456 7.43877 5.31761 7.38478C5.29065 7.3308 5.25115 7.28175 5.20134 7.24043L1.82977 4.44438L14.4643 4.44438C14.6064 4.44438 14.7426 4.39756 14.8431 4.31423C14.9436 4.23089 15 4.11786 15 4Z" />
                            </svg>
                        </Link>

                        <svg class="scroll-indicator" width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.5 15L22.5 30L7.50001 15" stroke="white" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

                <MobileVideo src={"https://player.vimeo.com/video/586008328?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=a3338dedf8"} fullscreen={fullscreenMobile} changeFullscreen={changeFullscreenMobile} context="mobile"></MobileVideo>
                
                <WeddingsSection />

                <AboutSection mobile/>

                <PhotographySection mobile={true} />

                <ContactSection />
            </div>


            {/* DEKSTOP VIEW */}

            <div className="home-desktop-view">
                <div className="home-background-video-container">
                    <div className="overlay">
                    </div>
                    <video onPlay={() => fadeInVideo()} autoPlay muted loop id="home-background-video" className="home-background-video">
                        <source src="resources/videos/video-reel.mp4" type="video/mp4" />
                    </video>
                </div>

                <div className="home-buttons">
                    <div className="reel-cta" onClick={(e) => { changeFullscreen(true) }}>
                        <Link to="">WATCH&nbsp;PRODUCTION&nbsp;REEL</Link>
                    </div>
                    
                    <Link to="/contact">BOOKING&nbsp;&amp;&nbsp;INQUIRIES
                        <svg width="38" height="20" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M15 4C15 3.88214 14.9436 3.76911 14.8431 3.68577C14.7426 3.60244 14.6064 3.55562 14.4643 3.55562L1.82977 3.55562L5.20134 0.759566C5.25115 0.718249 5.29065 0.669199 5.31761 0.615216C5.34456 0.561233 5.35844 0.503374 5.35844 0.444943C5.35844 0.386513 5.34456 0.328654 5.31761 0.274671C5.29065 0.220688 5.25115 0.171638 5.20134 0.130321C5.15154 0.089004 5.09241 0.0562298 5.02733 0.0338693C4.96226 0.0115088 4.89251 0 4.82208 0C4.75164 0 4.6819 0.0115088 4.61682 0.0338693C4.55175 0.0562298 4.49262 0.089004 4.44282 0.130321L0.157376 3.68538C0.10749 3.72666 0.0679111 3.77569 0.0409059 3.82968C0.0139007 3.88367 2.94372e-09 3.94155 2.98738e-09 4C3.03103e-09 4.05845 0.0139007 4.11633 0.0409059 4.17032C0.0679111 4.2243 0.10749 4.27334 0.157376 4.31462L4.44282 7.86968C4.49262 7.911 4.55175 7.94377 4.61682 7.96613C4.6819 7.98849 4.75164 8 4.82208 8C4.89251 8 4.96226 7.98849 5.02733 7.96613C5.09241 7.94377 5.15154 7.911 5.20134 7.86968C5.25115 7.82836 5.29065 7.77931 5.31761 7.72533C5.34456 7.67135 5.35844 7.61349 5.35844 7.55506C5.35844 7.49662 5.34456 7.43877 5.31761 7.38478C5.29065 7.3308 5.25115 7.28175 5.20134 7.24043L1.82977 4.44438L14.4643 4.44438C14.6064 4.44438 14.7426 4.39756 14.8431 4.31423C14.9436 4.23089 15 4.11786 15 4Z" />
                        </svg>
                    </Link>

                    <svg class="scroll-indicator" width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.5 15L22.5 30L7.50001 15" stroke="white" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className={`home-desktop-mute${muted ? "" : " home-desktop-mute--hidden"}`} onClick={() => {setMuted(!muted)}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 3.74952C12.0001 3.60412 11.9579 3.46183 11.8786 3.33996C11.7993 3.2181 11.6863 3.12191 11.5533 3.0631C11.4204 3.0043 11.2732 2.98541 11.1296 3.00874C10.9861 3.03208 10.8525 3.09662 10.745 3.19452L5.46 7.99952H2.75C2.28587 7.99952 1.84075 8.18389 1.51256 8.51208C1.18437 8.84027 1 9.28539 1 9.74952V14.2495C1 15.2155 1.784 15.9995 2.75 15.9995H5.46L10.745 20.8045C10.8525 20.9024 10.9861 20.967 11.1296 20.9903C11.2732 21.0136 11.4204 20.9947 11.5533 20.9359C11.6863 20.8771 11.7993 20.7809 11.8786 20.6591C11.9579 20.5372 12.0001 20.3949 12 20.2495V3.74952ZM6.255 9.30452L10.5 5.44452V18.5545L6.255 14.6945C6.11681 14.5689 5.93674 14.4994 5.75 14.4995H2.75C2.6837 14.4995 2.62011 14.4732 2.57322 14.4263C2.52634 14.3794 2.5 14.3158 2.5 14.2495V9.74952C2.5 9.68322 2.52634 9.61963 2.57322 9.57274C2.62011 9.52586 2.6837 9.49952 2.75 9.49952H5.75C5.93674 9.49966 6.11681 9.43012 6.255 9.30452Z" fill="white" />
                        <path d="M16.2792 8.22015C16.2105 8.14647 16.1277 8.08736 16.0357 8.04637C15.9437 8.00538 15.8444 7.98334 15.7437 7.98156C15.643 7.97979 15.543 7.99831 15.4496 8.03603C15.3562 8.07375 15.2714 8.1299 15.2001 8.20112C15.1289 8.27233 15.0728 8.35717 15.0351 8.45056C14.9973 8.54394 14.9788 8.64397 14.9806 8.74468C14.9824 8.84538 15.0044 8.94469 15.0454 9.03669C15.0864 9.12869 15.1455 9.21149 15.2192 9.28015L17.9392 12.0002L15.2192 14.7202C15.1455 14.7888 15.0864 14.8716 15.0454 14.9636C15.0044 15.0556 14.9824 15.1549 14.9806 15.2556C14.9788 15.3563 14.9973 15.4564 15.0351 15.5498C15.0728 15.6431 15.1289 15.728 15.2001 15.7992C15.2714 15.8704 15.3562 15.9266 15.4496 15.9643C15.543 16.002 15.643 16.0205 15.7437 16.0187C15.8444 16.017 15.9437 15.9949 16.0357 15.9539C16.1277 15.9129 16.2105 15.8538 16.2792 15.7802L18.9992 13.0602L21.7192 15.7802C21.7878 15.8538 21.8706 15.9129 21.9626 15.9539C22.0546 15.9949 22.154 16.017 22.2547 16.0187C22.3554 16.0205 22.4554 16.002 22.5488 15.9643C22.6422 15.9266 22.727 15.8704 22.7982 15.7992C22.8694 15.728 22.9256 15.6431 22.9633 15.5498C23.001 15.4564 23.0195 15.3563 23.0178 15.2556C23.016 15.1549 22.994 15.0556 22.953 14.9636C22.912 14.8716 22.8529 14.7888 22.7792 14.7202L20.0592 12.0002L22.7792 9.28015C22.9117 9.13798 22.9838 8.94993 22.9804 8.75563C22.9769 8.56133 22.8982 8.37594 22.7608 8.23853C22.6234 8.10112 22.438 8.02241 22.2437 8.01898C22.0494 8.01555 21.8614 8.08767 21.7192 8.22015L18.9992 10.9402L16.2792 8.22015Z" fill="white" />
                    </svg>
                </div>
                <div className={`home-desktop-unmute${muted ? " home-desktop-unmute--hidden" : ""}`} onClick={() => {setMuted(!muted)}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.553 3.06442C11.686 3.12315 11.799 3.21925 11.8784 3.34104C11.9577 3.46283 12 3.60506 12 3.75042V20.2504C12.0001 20.3958 11.9579 20.5381 11.8786 20.66C11.7993 20.7818 11.6863 20.878 11.5533 20.9368C11.4204 20.9956 11.2732 21.0145 11.1296 20.9912C10.9861 20.9679 10.8525 20.9033 10.745 20.8054L5.46 16.0004H2.75C2.28587 16.0004 1.84075 15.816 1.51256 15.4879C1.18437 15.1597 1 14.7145 1 14.2504V9.75042C1 8.78442 1.784 8.00042 2.75 8.00042H5.46L10.745 3.19542C10.8526 3.09782 10.9863 3.03358 11.1297 3.0105C11.2731 2.98742 11.4202 3.0065 11.553 3.06542V3.06442ZM10.5 5.44542L6.255 9.30542C6.11681 9.43102 5.93674 9.50056 5.75 9.50042H2.75C2.6837 9.50042 2.62011 9.52676 2.57322 9.57364C2.52634 9.62053 2.5 9.68412 2.5 9.75042V14.2504C2.5 14.3884 2.612 14.5004 2.75 14.5004H5.75C5.93674 14.5003 6.11681 14.5698 6.255 14.6954L10.5 18.5554V5.44542Z" fill="white" />
                        <path d="M18.7174 4.22227C18.858 4.08182 19.0486 4.00293 19.2474 4.00293C19.4461 4.00293 19.6368 4.08182 19.7774 4.22227C24.0734 8.51827 24.0734 15.4823 19.7774 19.7783C19.6352 19.9107 19.4472 19.9829 19.2529 19.9794C19.0586 19.976 18.8732 19.8973 18.7358 19.7599C18.5984 19.6225 18.5196 19.4371 18.5162 19.2428C18.5128 19.0485 18.5849 18.8604 18.7174 18.7183C19.5997 17.8361 20.2996 16.7888 20.7771 15.6361C21.2546 14.4834 21.5004 13.2479 21.5004 12.0003C21.5004 10.7526 21.2546 9.51714 20.7771 8.36446C20.2996 7.21178 19.5997 6.16444 18.7174 5.28227C18.5769 5.14164 18.498 4.95102 18.498 4.75227C18.498 4.55352 18.5769 4.3629 18.7174 4.22227Z" fill="white" />
                        <path d="M16.2436 7.75685C16.174 7.68718 16.0913 7.63192 16.0002 7.59422C15.9092 7.55652 15.8117 7.53711 15.7131 7.53711C15.6146 7.53711 15.517 7.55652 15.426 7.59422C15.335 7.63192 15.2523 7.68718 15.1826 7.75685C15.113 7.82652 15.0577 7.90922 15.02 8.00025C14.9823 8.09127 14.9629 8.18883 14.9629 8.28735C14.9629 8.38587 14.9823 8.48343 15.02 8.57445C15.0577 8.66548 15.113 8.74818 15.1826 8.81785C15.6005 9.23571 15.932 9.73179 16.1581 10.2778C16.3843 10.8237 16.5007 11.4089 16.5007 11.9998C16.5007 12.5908 16.3843 13.176 16.1581 13.7219C15.932 14.2679 15.6005 14.764 15.1826 15.1818C15.0502 15.324 14.978 15.5121 14.9815 15.7064C14.9849 15.9007 15.0636 16.0861 15.201 16.2235C15.3384 16.3609 15.5238 16.4396 15.7181 16.443C15.9124 16.4465 16.1005 16.3743 16.2426 16.2418C17.3678 15.1166 17.9998 13.5906 17.9998 11.9994C17.9998 10.4081 17.3678 8.88206 16.2426 7.75685H16.2436Z" fill="white" />
                    </svg>
                </div>

                <DesktopVideo src={"https://player.vimeo.com/video/586008328?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=a3338dedf8"} fullscreen={fullscreen} changeFullscreen={changeFullscreen}></DesktopVideo>

                <WeddingsSection />

                <AboutSection mobile={false} />

                <PhotographySection mobile={false} />

                <ContactSection />
            </div>
        </Layout>
    );
}

export default Home;
