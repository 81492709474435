// import { useState, useEffect } from "react"

import { Layout, PhotoData, PhotographyCard } from "../../exports";

import "./photography.scss";

function Photography() {

    const scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <Layout cn={"photography"} footerColor="dark">
            <div className="photography-page-section photography-page-section--mobile">
                <h2>PHOTOGRAPHY FOR ANY OCCASION</h2>

                <div className="photography-page-section-cards">
                    <PhotographyCard mobile title="Commercial" url="/photography/commercial" thumbnail="resources/thumbnails/photography/commercial.jpg">
                        {PhotoData.commercial.description}
                    </PhotographyCard>

                    <PhotographyCard mobile title="Events" url="/photography/events" thumbnail="resources/thumbnails/photography/events.jpg">
                        {PhotoData.events.description}
                    </PhotographyCard>

                    <PhotographyCard mobile title="Portraits" url="/photography/portraits" thumbnail="resources/thumbnails/photography/portraits.jpg">
                        {PhotoData.portraits.description}
                    </PhotographyCard>

                    <PhotographyCard mobile title="Real Estate" url="/photography/real-estate" thumbnail="resources/thumbnails/photography/real-estate.jpg">
                        {PhotoData["real-estate"].description}
                    </PhotographyCard>
                </div>
            </div>
        </Layout>
    )
}

export default Photography;