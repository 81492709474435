import { useState, useEffect } from "react";

import { CommercialItem } from "../../exports";

import "./commercial-list.scss";

let items = [];

function CommercialList(props) {

    const [listItems, setListItems] = useState([]);
    const [data, setData] = useState({});

    if (items.length === 0) {
        
        for (let i = 0; i < 4; i++) {
            items.push(<CommercialItem key={i} video={props.dataSet[Object.keys(props.dataSet)[i]].preview} title={props.dataSet[Object.keys(props.dataSet)[i]].title} desc={props.dataSet[Object.keys(props.dataSet)[i]].description} url={Object.keys(props.dataSet)[i]}/>);
        }
    }

    // load items
    useEffect(() => {

        setData(props.dataSet)

        setListItems([...items]);

        if (props.loadNum > 4 && items.length < props.max) {
            
            for (let i = listItems.length; i < props.loadNum; i++) {
                items.push(<CommercialItem key={i} video={data[Object.keys(data)[i]].preview} title={data[Object.keys(data)[i]].title} desc={data[Object.keys(data)[i]].description} url={Object.keys(data)[i]}/>);
            }

            setListItems([...items]);
        }
    }, [data, props.dataSet, props.max, props.loadNum, props.dataSet.thumbnails, props.dataSet.titles, props.dataSet.descriptions, props.dataSet.urls, listItems.length]);
    

    return (
        <ul className="commercial-list">
            {listItems}
        </ul>
    );
}

export default CommercialList;
