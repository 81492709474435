import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Layout, PhotoData, PhotographyFullscreenView } from "../../exports";

import "./photography-viewer.scss";


function PhotographyViewer() {

    let { collection } = useParams();

    let [ fullscreen, setFullscreen ] = useState(false);
    let [ clickedImage, setClickedImage ] = useState(PhotoData[collection].sources[0]);
    let [ wide, setWide ] = useState();
    let [ orientationSupported, setOrientationSupported ] = useState();
    let [ rotated,  setRotated ] = useState(false);

    let urls = PhotoData[collection].sources;

    useEffect(() => {
        if(orientationSupported) {
            if(!fullscreen) {
                document.documentElement.style.overflow = null;

                if(document.fullscreenElement) {
                    document.exitFullscreen();
                }

            } else {
                document.documentElement.style.overflow = "hidden";

                if(!document.fullscreenElement) {
                    if(document.querySelector(".photography-fullscreen-container").requestFullscreen)
                    document.querySelector(".photography-fullscreen-container").requestFullscreen();
                    else if(document.querySelector(".photography-fullscreen-container").webkitRequestFullScreen)
                    document.querySelector(".photography-fullscreen-container").webkitRequestFullScreen();
                }
            }
        }
    }, [fullscreen, orientationSupported])

    function handleFullscreenChange() {
        if(!document.fullscreenElement) {
            setFullscreen(false);
        }
    }

    const scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // add orientation change & fullscreen event listeners
    useEffect(() => {
        if (window.screen.orientation !== undefined) {

            window.screen.orientation.addEventListener('change', () => {
                if (window.screen.orientation.type === "landscape-primary" || window.screen.orientation.type === "landscape-secondary") {
                    setRotated(true)
                } else {
                    setRotated(false);
                }
            })
        } else {
            setOrientationSupported(false);
        }

        document.onfullscreenchange = () => handleFullscreenChange();
    }, [clickedImage])


    return (
        <Layout cn={"photography"} footerColor="medium-dark">
            <div className="photography-viewer-header">
                <Link to="/photography" onClick={() => {scrollTop()}}>
                    <svg width="30" height="16" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 4C15 3.88214 14.9436 3.76911 14.8431 3.68577C14.7426 3.60244 14.6064 3.55562 14.4643 3.55562L1.82977 3.55562L5.20134 0.759566C5.25115 0.718249 5.29065 0.669199 5.31761 0.615216C5.34456 0.561233 5.35844 0.503374 5.35844 0.444943C5.35844 0.386513 5.34456 0.328654 5.31761 0.274671C5.29065 0.220688 5.25115 0.171638 5.20134 0.130321C5.15154 0.089004 5.09241 0.0562298 5.02733 0.0338693C4.96226 0.0115088 4.89251 0 4.82208 0C4.75164 0 4.6819 0.0115088 4.61682 0.0338693C4.55175 0.0562298 4.49262 0.089004 4.44282 0.130321L0.157376 3.68538C0.10749 3.72666 0.0679111 3.77569 0.0409059 3.82968C0.0139007 3.88367 2.94372e-09 3.94155 2.98738e-09 4C3.03103e-09 4.05845 0.0139007 4.11633 0.0409059 4.17032C0.0679111 4.2243 0.10749 4.27334 0.157376 4.31462L4.44282 7.86968C4.49262 7.911 4.55175 7.94377 4.61682 7.96613C4.6819 7.98849 4.75164 8 4.82208 8C4.89251 8 4.96226 7.98849 5.02733 7.96613C5.09241 7.94377 5.15154 7.911 5.20134 7.86968C5.25115 7.82836 5.29065 7.77931 5.31761 7.72533C5.34456 7.67135 5.35844 7.61349 5.35844 7.55506C5.35844 7.49662 5.34456 7.43877 5.31761 7.38478C5.29065 7.3308 5.25115 7.28175 5.20134 7.24043L1.82977 4.44438L14.4643 4.44438C14.6064 4.44438 14.7426 4.39756 14.8431 4.31423C14.9436 4.23089 15 4.11786 15 4Z" />
                    </svg>
                </Link>
                <h1>{collection}</h1>
            </div>

            <div>
                {urls.map((key, index) => 
                    <img key={index.toString()} className="photography-viewer-image" src={key[0]} alt="" onClick={() => {setClickedImage(key); setFullscreen(true);}}/>
                    )}
            </div>

            <PhotographyFullscreenView display={fullscreen} rotated={rotated} image={clickedImage}>
                <div className={`fullscreen-controls${rotated ? " fullscreen-controls--rotated" : ""}${wide && orientationSupported ? " fullscreen-controls--rotatable" : ""}`}>
                    <img className={"fullscreen-close"} onClick={() => setFullscreen(false)} src="/resources/graphics/fullscreen-close.svg" alt=""></img>
                </div>
            </PhotographyFullscreenView>
        </Layout>
    );
}

export default PhotographyViewer;
