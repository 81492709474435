const data = {
    generalInfoText1: "Team Apollo’s mission is to provide professional video and photography services, while pushing the boundaries of creativity. The result is a product that will make you stand out from the crowd like no other.",
    generalInfoText2: "We offer services for advertisements, weddings, events, music videos, and much more.",
    generalInfoImg: "/resources/thumbnails/commercial/kayaking-adventure/kayaking-adventure-thumbnail-1.jpg",

    commercialInfoText: "Advertise your business or event with Team Apollo. Whether it be advertisements, promos, or coverage videos, our commercial services are sure to create engagement.",
    commercialInfoImg: "/resources/thumbnails/commercial/bleachd-apparel/bleachd-apparel-thumbnail-1.jpg",


    musicVideosInfoText: "We help artists bring their vision to life with stunning and creative music videos. We focus on taking the energy and creative direction of artists and turning that into a music video fans will love.",
    musicVideosInfoImg: "/resources/thumbnails/music-videos/demon/demon-thumbnail-1.jpg",
}

export default data;