import { useEffect } from 'react';

import "./mobile-video.scss";

function MobileVideo(props) {

    useEffect(() => {
        let vid = document.querySelector('iframe');
        let player = new window.Vimeo.Player(vid);

        let exit = document.getElementsByClassName('exit-controls')[0];
        exit.addEventListener('click', () => {
            player.pause();
        })
    }, [])

    useEffect(() => {
        if (props.fullscreen === true) {
            document.getElementsByClassName("exit-controls")[0].style.animation = "slide-up 0.3s forwards";
            // document.getElementsByClassName("shader")[0].style.opacity = 1;
            document.getElementsByClassName("media-video-desktop")[0].style.animation = "0.3s ease 0s 1 normal both running fade-in";
        }
    })

    return (
        <div className="media-video-mobile-container">

            <div className={`overlay${props.fullscreen ? " overlay--fullscreen" : ""}`} onClick={props.fullscreen ? () => { props.changeFullscreen(false) } : null}>
                {/* <span className="exit-button" onClick={props.fullscreen ? () => { props.changeFullscreen(false) } : null}>CLOSE</span> */}

            </div>
            <div className={`shader${props.fullscreen ? " shader--fullscreen" : ""}`} />
            <div className={`exit-controls${props.fullscreen ? " exit-controls--open" : ""}`} onClick={props.fullscreen ? () => { props.changeFullscreen(false) } : null}>
                {/* <svg className="exit-button" onClick={props.fullscreen ? () => { props.changeFullscreen(false) } : null} width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.971 31.0003L44.9298 21.0415C45.421 20.5061 45.6864 19.8016 45.6707 19.0751C45.6549 18.3486 45.3592 17.6563 44.8452 17.1426C44.3312 16.6289 43.6387 16.3337 42.9122 16.3184C42.1857 16.3031 41.4814 16.569 40.9463 17.0606L40.9488 17.058L30.9901 27.0168L21.0313 17.058C20.4959 16.5668 19.7914 16.3013 19.0649 16.3171C18.3384 16.3328 17.6461 16.6286 17.1324 17.1425C16.6188 17.6565 16.3235 18.3491 16.3082 19.0756C16.2929 19.8021 16.5588 20.5064 17.0504 21.0415L17.0478 21.0389L27.0066 30.9977L17.0478 40.9564C16.7676 41.2135 16.5424 41.5246 16.3856 41.871C16.2288 42.2174 16.1437 42.592 16.1354 42.9721C16.1272 43.3523 16.196 43.7302 16.3376 44.0831C16.4792 44.436 16.6908 44.7566 16.9596 45.0255C17.2284 45.2945 17.5488 45.5063 17.9016 45.6481C18.2544 45.79 18.6323 45.859 19.0124 45.851C19.3926 45.843 19.7672 45.7582 20.1137 45.6016C20.4603 45.445 20.7715 45.22 21.0288 44.9399L21.0313 44.9373L30.9901 34.9786L40.9488 44.9373C41.2059 45.2175 41.517 45.4428 41.8634 45.5996C42.2098 45.7564 42.5844 45.8415 42.9646 45.8498C43.3447 45.858 43.7226 45.7892 44.0755 45.6476C44.4284 45.5059 44.749 45.2944 45.0179 45.0256C45.2869 44.7568 45.4987 44.4363 45.6405 44.0836C45.7824 43.7308 45.8514 43.3529 45.8434 42.9727C45.8354 42.5926 45.7506 42.218 45.594 41.8714C45.4374 41.5249 45.2124 41.2137 44.9323 40.9564L44.9298 40.9538L34.971 31.0003Z" fill="white" />
                </svg> */}
                <div className="exit-controls-container">
                    <svg className="exit-button" width="40" height="21" viewBox="0 0 40 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M40 10.5C40 10.8094 39.8495 11.1061 39.5816 11.3248C39.3137 11.5436 38.9504 11.6665 38.5715 11.6665L4.87938 11.6665L13.8702 19.0061C14.0031 19.1146 14.1084 19.2434 14.1803 19.3851C14.2522 19.5268 14.2892 19.6786 14.2892 19.832C14.2892 19.9854 14.2522 20.1373 14.1803 20.279C14.1084 20.4207 14.0031 20.5495 13.8702 20.6579C13.7374 20.7664 13.5798 20.8524 13.4062 20.9111C13.2327 20.9698 13.0467 21 12.8589 21C12.671 21 12.4851 20.9698 12.3115 20.9111C12.138 20.8524 11.9803 20.7664 11.8475 20.6579L0.419669 11.3259C0.28664 11.2175 0.181095 11.0888 0.109081 10.9471C0.0370676 10.8054 -9.0067e-07 10.6534 -9.14026e-07 10.5C-9.27383e-07 10.3466 0.0370675 10.1946 0.109081 10.0529C0.181095 9.9112 0.286639 9.78248 0.419669 9.67412L11.8475 0.342095C11.9803 0.233638 12.138 0.147605 12.3115 0.0889081C12.4851 0.0302113 12.671 2.70222e-06 12.8589 2.68573e-06C13.0467 2.66924e-06 13.2327 0.0302112 13.4062 0.088908C13.5798 0.147605 13.7374 0.233638 13.8702 0.342095C14.0031 0.450551 14.1084 0.579306 14.1803 0.721013C14.2522 0.862717 14.2892 1.0146 14.2892 1.16798C14.2892 1.32136 14.2522 1.47324 14.1803 1.61494C14.1084 1.75665 14.0031 1.88541 13.8702 1.99386L4.87938 9.3335L38.5715 9.3335C38.9504 9.3335 39.3137 9.45639 39.5816 9.67516C39.8495 9.89392 40 10.1906 40 10.5Z" fill="white" />
                    </svg>
                    <span className="exit-text">BACK</span>
                </div>
            </div>

            <div className={props.context !== "mobile" ? `media-video-desktop${props.fullscreen ? " media-video-desktop--open" : ""}` : `media-video-mobile${props.fullscreen ? " media-video-mobile--open" : ""}`} >

                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe title={props.title} src={props.src} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></iframe>
                </div>
            </div>
        </div>
    );
}

export default MobileVideo;
