import { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./styles/global.scss";
import "./styles/App.scss";

import { Home, Commercial, CommercialPlayer, CommercialDesktopViewer, Weddings, WeddingsDesktop, MusicVideos, MusicVideoPlayer, MusicVideoDesktopViewer, Photography, PhotographyViewer, PhotographyDesktop, PhotographyDesktopViewer, Bio, BioDesktop, Contact, ContactDesktop } from "./exports"

import { useDispatch } from 'react-redux'
import { set } from './features/pageWidth'

function App() {

    const dispatch = useDispatch()

    const [ view, setView ] = useState();
    const desktopView = useRef(null);

    window.addEventListener("resize", () => {
        changeLayout();
        dispatch(set(document.body.clientWidth));
    });

    function changeLayout() {
        if (window.innerWidth < 1024 && (desktopView.current || desktopView.current === null)) {
            desktopView.current = false;
            setView(
                <Routes>
                    <Route path="/" exact element={ <Home />} />
                    <Route path="/commercial" exact element={ <Commercial />} />
                    <Route path="/commercial/:video" exact element={ <CommercialPlayer />} />
                    <Route path="/weddings" exact element={ <Weddings />} />
                    <Route path="/music-videos" exact element={ <MusicVideos />} />
                    <Route path="/music-videos/:video" exact element={ <MusicVideoPlayer />} />
                    <Route path="/photography" exact element={ <Photography />} />
                    <Route path="/photography/:collection" exact element={ <PhotographyViewer />} />
                    <Route path="/bio" exact element={ <Bio />} />
                    <Route path="/contact" exact element={ <Contact />} />
                </Routes>
            );
        } else if (window.innerWidth >= 1024 && (!desktopView.current || desktopView.current === null)){
            desktopView.current = true;
            setView(
                <Routes>
                    <Route path="/" exact element={ <Home />} />
                    <Route path="/commercial" exact element={ <CommercialDesktopViewer />} />
                    <Route path="/commercial/:video" exact element={ <CommercialDesktopViewer />} />
                    <Route path="/weddings" exact element={ <WeddingsDesktop />} />
                    <Route path="/music-videos" exact element={ <MusicVideoDesktopViewer />} />
                    <Route path="/music-videos/:video" exact element={ <MusicVideoDesktopViewer />} />
                    <Route path="/photography" exact element={ <PhotographyDesktop />} />
                    <Route path="/photography/:collection" exact element={ <PhotographyDesktopViewer />} />
                    <Route path="/bio" exact element={ <BioDesktop />} />
                    <Route path="/contact" exact element={ <ContactDesktop />} />
                </Routes>
            );
        }
    }

    useEffect(() => {
        changeLayout();
        dispatch(set(document.body.clientWidth));
    }, []);

    return (
        <div className="App">
            <Router>
                {view}
            </Router>
        </div>
    );
}

export default App;
