import { Link } from "react-router-dom";

import "./button.scss";

function Button(props) {

    return (
        props.external ?
        (
            <div className={`button${props.buttonOutlined ? " button-outlined--" + props.buttonColor : " button--" + props.buttonColor}`}>
                <a href={props.buttonDest} target="_blank" rel="noreferrer">
                    {props.children}
                </a>
            </div>
        ) : (
            <div className={`button${props.buttonOutlined ? " button-outlined--" + props.buttonColor : " button--" + props.buttonColor}`}>
                <Link to={props.buttonDest}>
                    {props.children}
                </Link>
            </div>
        )
    );
}

export default Button;