import { Link } from "react-router-dom";

import "./photography-section-item.scss";

function PhotographySectionItem(props) {
    return (
        <div className="photography-section-item" style={{backgroundImage: `url(${props.thumbnail})`}}>
            <Link to={`/photography/${props.url}`}>
                <div className="photography-section-item-container">
                    <div className="photography-section-item-title">
                        <h2>{props.title}</h2>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default PhotographySectionItem;
