import { useRef, useState, useEffect } from 'react';

import './desktop-media-about.scss';

function DesktopMediaAbout(props) {

    const list = useRef();

    let dataCopy = JSON.stringify(props.dataSet);
    dataCopy = JSON.parse(dataCopy);

    const newData = useRef(dataCopy);


    // carousel data
    // TODO: maybe use ref here
    const [ pageIndex, setPageIndex ] = useState(1);

    const pos = useRef(0);

    const slideWidth = useRef(375);
    const slideGap = useRef(16);
    const step = useRef();
    const [max, setMax] = useState();

    function moveCarousel(forwards) {

        /* this condition checking allows moveCarousel to be called without the forwards
        //  parameter, to move to previously specified pos.current and pageIndex */
        if (forwards === false && pageIndex > 1) {
            pos.current += step.current;
            setPageIndex(pageIndex - 1);
            console.log(max)
        } else if (forwards && pageIndex < Math.ceil(Object.keys(newData.current).length / max)){
            pos.current -= step.current;
            setPageIndex(pageIndex + 1);
        }

        document.getElementsByClassName("media-viewer-about-images__wrapper")[0].style.left = `${pos.current}px`;
    }

    function resetCarousel() {
        setPageIndex(1);
        pos.current = 0;
        document.getElementsByClassName("media-viewer-about-images__wrapper")[0].style.left = "0px";
    }

    function changeMax(size) {
        let newMax = Math.floor((size - 88) / (slideWidth.current + slideGap.current));
        if (max !== newMax) {
            setMax(newMax);
        }
        step.current = (slideWidth.current * newMax) + (slideGap.current * newMax);

        document.getElementsByClassName("media-viewer-about-images")[0].style.width = `${step.current + 16}px`;
        document.getElementsByClassName("media-viewer-about-controls")[0].style.width = `${step.current + 16}px`;
    }
    
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    useEffect(() => {

       function changeMaxWidth() {
           if (window.innerWidth >= 1024) {
               changeMax(document.getElementsByClassName("media-viewer-about-wrapper")[0].clientWidth);
           }
        }

        window.addEventListener("resize", changeMaxWidth());

        changeMaxWidth();

        return(window.removeEventListener("resize", changeMaxWidth()));
    }, []);

    useEffect(() => {
        resetCarousel();
    }, [max])
    
    // on video change, modify list data
    useEffect(() => {

        function moveCarousel(forwards) {

            /* this condition checking allows moveCarousel to be called without the forwards
            //  parameter, to move to previously specified pos.current and pageIndex */
            if (forwards === false && pageIndex > 1) {
                pos.current += step.current;
                setPageIndex(pageIndex - 1);
            } else if (forwards && pageIndex < Math.ceil(Object.keys(newData.current).length / 3)) {
                pos.current -= step.current;
                setPageIndex(pageIndex + 1);
            }

            document.getElementsByClassName("media-viewer-about-images__wrapper")[0].style.left = `${pos.current}px`;
        }

        // resetCarousel();

        let dataCopy = JSON.stringify(props.dataSet);
        dataCopy = JSON.parse(dataCopy);

        newData.current = dataCopy;

        // newData.current = TODO
        delete newData.current[Object.keys(newData.current)[props.index]]
        const dataKeys = Object.keys(newData.current);

        const tempList = [];

        for(let i=0; i < dataKeys.length; i++) {
            tempList.push(
                <div key={i} onClick={() => { resetCarousel(); scrollToTop(); props.setVideoIndex(undefined, undefined, Object.keys(props.dataSet).indexOf(dataKeys[i])); setTimeout(() => { moveCarousel()}, 750)}} className="media-viewer-about-image" style={{ backgroundImage: `url(${newData.current[dataKeys[i]].preview})` }}>
                    <div className="media-viewer-about-image-data">
                        <h1 className="media-viewer-about-image-data__title">
                            {newData.current[dataKeys[i]].title}
                            {/* <svg onClick={() => {changeInfoState(true)}} viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29 10H27V8H29V10ZM29 12H27V18H29V12ZM28 5C23.59 5 20 8.59 20 13C20 17.41 23.59 21 28 21C32.41 21 36 17.41 36 13C36 8.59 32.41 5 28 5ZM28 3C33.523 3 38 7.477 38 13C38 18.523 33.523 23 28 23C22.477 23 18 18.523 18 13C18 7.477 22.477 3 28 3Z" fill="white" />
                            </svg> */}

                        </h1>
                        <hr />
                        <span>
                            {/* {newData.current[dataKeys[i]].location} */}
                            {props.context === "commercial" ? newData.current[dataKeys[i]].location : newData.current[dataKeys[i]].artist}
                        </span>
                    </div>
                </div>
            )
        }

        list.current = tempList;

        // return () => {
        //     cleanup
        // }
    }, [props])

    let descParagraph;
    if(props.context === "commercial") {
        descParagraph = (
            <p className="media-viewer-about-info-text__paragraph">{props.desc}<br /><br />We do <b>{props.descOffers}</b></p>
        )
    } else {
        descParagraph = (
            <p className="media-viewer-about-info-text__paragraph">{props.desc}<br /><br />We also offer Spotify canvas visuals to complement your video.</p>
        )
    }

    return (
        <div className="media-viewer-about-wrapper">
            <div className="media-viewer-about">
                <div className="media-viewer-about-info">
                    <h1 className="media-viewer-about-info__header">{props.title}</h1>
                    <div className="media-viewer-about-info-text">
                        {descParagraph}
                    </div>

                    {/* <div className="media-viewer-about-info__cta">
                        <Link to="/contact">BOOKING &amp; INQUIRIES
                            <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.37833e-06 6C1.39392e-06 6.17679 0.0714888 6.34633 0.198738 6.47134C0.325986 6.59635 0.498573 6.66657 0.678529 6.66657L16.6823 6.66657L12.4116 10.8607C12.3486 10.9226 12.2985 10.9962 12.2644 11.0772C12.2302 11.1582 12.2126 11.2449 12.2126 11.3326C12.2126 11.4202 12.2302 11.507 12.2644 11.588C12.2985 11.669 12.3486 11.7425 12.4116 11.8045C12.4747 11.8665 12.5496 11.9157 12.632 11.9492C12.7145 11.9827 12.8028 12 12.892 12C12.9813 12 13.0696 11.9827 13.152 11.9492C13.2345 11.9157 13.3093 11.8665 13.3724 11.8045L18.8007 6.47193C18.8638 6.41001 18.914 6.33646 18.9482 6.25547C18.9824 6.17449 19 6.08768 19 6C19 5.91232 18.9824 5.82551 18.9482 5.74452C18.914 5.66354 18.8638 5.58998 18.8007 5.52807L13.3724 0.19548C13.3093 0.133505 13.2345 0.0843435 13.152 0.0508027C13.0696 0.017262 12.9813 -1.38117e-06 12.892 -1.37337e-06C12.8028 -1.36557e-06 12.7145 0.0172621 12.632 0.0508028C12.5496 0.0843435 12.4747 0.133505 12.4116 0.19548C12.3485 0.257455 12.2985 0.33103 12.2644 0.412005C12.2302 0.49298 12.2126 0.579768 12.2126 0.667414C12.2126 0.755061 12.2302 0.841848 12.2644 0.922823C12.2985 1.0038 12.3485 1.07737 12.4116 1.13935L16.6823 5.33343L0.678529 5.33343C0.498573 5.33343 0.325986 5.40366 0.198738 5.52866C0.0714888 5.65367 1.36274e-06 5.82321 1.37833e-06 6Z" />
                            </svg>
                        </Link>
                    </div> */}
                </div>

                <div className="media-viewer-about-images">
                    <div className="media-viewer-about-images__wrapper">
                        { list.current }
                    </div>
                </div>

            </div>
            <div className="media-viewer-about-controls">
                <div className={`media-viewer-about-controls__left${pageIndex === 1 ? " media-viewer-about-controls__left--inactive" : ""}`} onClick={() => moveCarousel(false)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.03 4.00012C12.1203 4.08383 12.1932 4.18451 12.2446 4.2964C12.2959 4.4083 12.3248 4.52922 12.3294 4.65226C12.3341 4.7753 12.3144 4.89805 12.2716 5.0135C12.2288 5.12895 12.1637 5.23484 12.08 5.32512L7.67121 10.0001L12.08 14.6751C12.1637 14.7654 12.2288 14.8713 12.2716 14.9867C12.3144 15.1022 12.334 15.2249 12.3294 15.348C12.3247 15.471 12.2959 15.5919 12.2445 15.7038C12.1932 15.8157 12.1202 15.9164 12.03 16.0001C11.9397 16.0838 11.8338 16.149 11.7183 16.1917C11.6029 16.2345 11.4801 16.2542 11.3571 16.2495C11.2341 16.2449 11.1131 16.2161 11.0013 16.1647C10.8894 16.1133 10.7887 16.0404 10.705 15.9501L5.70496 10.6376C5.54418 10.4643 5.45485 10.2366 5.45485 10.0001C5.45485 9.76368 5.54418 9.53598 5.70496 9.36262L10.705 4.05012C10.7887 3.95982 10.8894 3.88691 11.0012 3.83553C11.1131 3.78414 11.2341 3.75531 11.3571 3.75067C11.4801 3.74602 11.6029 3.76566 11.7183 3.80846C11.8338 3.85127 11.9397 3.91639 12.03 4.00012Z" fill="white" />
                    </svg>
                </div>
                <div className={`media-viewer-about-controls__right${pageIndex < Math.ceil(Object.keys(newData.current).length / max) ? "" : " media-viewer-about-controls__right--inactive"}`} onClick={() => moveCarousel(true)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.97004 15.9999C7.87975 15.9162 7.80683 15.8155 7.75545 15.7036C7.70407 15.5917 7.67523 15.4708 7.67059 15.3477C7.66595 15.2247 7.68559 15.1019 7.72839 14.9865C7.77119 14.871 7.83631 14.7652 7.92004 14.6749L12.3288 9.99988L7.92004 5.32489C7.83632 5.2346 7.77121 5.12871 7.72841 5.01326C7.68562 4.89782 7.66598 4.77507 7.67062 4.65203C7.67527 4.52899 7.7041 4.40807 7.75547 4.29618C7.80685 4.18428 7.87976 4.0836 7.97004 3.99989C8.06032 3.91617 8.16621 3.85105 8.28166 3.80826C8.39711 3.76546 8.51986 3.74583 8.64289 3.75047C8.76593 3.75511 8.88685 3.78394 8.99875 3.83532C9.11064 3.88669 9.21132 3.9596 9.29504 4.04988L14.295 9.36238C14.4558 9.53574 14.5452 9.76345 14.5452 9.99988C14.5452 10.2363 14.4558 10.464 14.295 10.6374L9.29504 15.9499C9.21133 16.0402 9.11065 16.1131 8.99875 16.1645C8.88686 16.2159 8.76594 16.2447 8.6429 16.2493C8.51986 16.254 8.39711 16.2343 8.28166 16.1915C8.16621 16.1487 8.06032 16.0836 7.97004 15.9999Z" fill="white" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default DesktopMediaAbout
