import { useState, useEffect } from "react";

import { MusicVideoItem } from "../../exports";

import "./music-video-list.scss";

let items = [];

function MusicVideoList(props) {

    const [listItems, setListItems] = useState([]);
    const [data, setData] = useState({});

    if (items.length === 0) {
        
        for (let i = 0; i < 4; i++) {
            items.push(<MusicVideoItem key={i} video={props.dataSet[Object.keys(props.dataSet)[i]].preview} title={props.dataSet[Object.keys(props.dataSet)[i]].title} artist={props.dataSet[Object.keys(props.dataSet)[i]].artist} url={Object.keys(props.dataSet)[i]}/>);
        }
    }

    useEffect(() => {

        setData(props.dataSet)

        setListItems([...items]);

        if (props.loadCount > 4 && items.length < props.max) {
            
            for (let i = listItems.length; i < props.loadNum; i++) {
                items.push(<MusicVideoItem key={i} video={data[Object.keys(data)][i].preview} title={data[Object.keys(data)][i].title} artist={data[Object.keys(data)][i].artist} url={data.urls[i]}/>);
            }

            setListItems([...items]);
        }
    }, [data, props.dataSet, props.max, props.loadNum, props.dataSet.thumbnails, props.dataSet.titles, props.dataSet.descriptions, props.dataSet.urls, listItems.length]);
    

    return (
        <ul className="music-video-list">
            {listItems}
        </ul>
    );
}

export default MusicVideoList;
