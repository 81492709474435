import { useState, useRef, useEffect } from "react";

import { MusicVideoItem, VideoDataMusic } from "../../exports";

import "./music-video-list-related.scss";

let items = [];

function MusicVideoListRelated(props) {

    let keys = Object.keys(VideoDataMusic);

    const contextIndex = Object.keys(VideoDataMusic).indexOf(props.currentVid);

    keys.splice(contextIndex, 1);

    let newData = JSON.stringify(VideoDataMusic);
    newData = JSON.parse(newData);

    const [listItems, setListItems] = useState([]);
    const data = useRef(newData);
    const [dataKeys, setDataKeys] = useState(keys);


    useEffect(() => {

        let keys = Object.keys(VideoDataMusic);

        const contextIndex = Object.keys(VideoDataMusic).indexOf(props.currentVid);

        keys.splice(contextIndex, 1);

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        setDataKeys(keys);
    }, [props.currentVid])


    // load items
    useEffect(() => {

        items = []

        if (items.length === 0) {

            for (let i = 0; i < 4; i++) {
                items.push(<MusicVideoItem key={i} video={data.current[dataKeys[i]].preview} title={data.current[dataKeys[i]].title} artist={data.current[dataKeys[i]].artist} url={dataKeys[i]} />);
            }

            setListItems([...items]);
        }


        // const loadNum = ( props.max > props.loadNum ? props.loadCount : props.max );

        if (props.loadNum > 4 && items.length < props.max) {

            for (let i = listItems.length; i < props.loadNum; i++) {
                items.push(<MusicVideoItem key={i} video={data.current[dataKeys[i]].preview} title={data.current[dataKeys[i]].title} artist={data.current[dataKeys[i]].artist} url={dataKeys[i]} />);
            }

            setListItems([...items]);
        }

    }, [dataKeys, props.loadNum, props.max]);


    return (
        <ul className="music-video-list">
            {[...listItems]}
        </ul>
    );
}

export default MusicVideoListRelated;
