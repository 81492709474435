import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, MusicVideoListRelated, MusicVideo, VideoDataMusic } from '../../exports';

import "./music-video-player.scss";

function MusicVideoPlayer() {

    let { video } = useParams();

    const contextIndex = Object.keys(VideoDataMusic).indexOf(video);
    let keys = Object.keys(VideoDataMusic);

    keys.splice(contextIndex, 1);

    const [loadNum, setLoadNum] = useState(4);

    const max = Object.keys(VideoDataMusic).length - 1;

    let title = VideoDataMusic[video].title;
    let artist = VideoDataMusic[video].artist;
    let url = VideoDataMusic[video].link;

    const scrollTop = () => {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    const loadMore = () => {

        if (loadNum >= max) {
            return;
        } else if (loadNum + 4 <= max) {
            setLoadNum(loadNum + 4);
        } else if (loadNum < max) {
            setLoadNum(loadNum + (max - loadNum));
        }
    }


    useEffect(() => {

        document.body.addEventListener("scroll", () => {
            if (document.body.scrollTop >= document.documentElement.scrollHeight - (window.innerHeight / 2)) {
                loadMore();
            }
        })
    })

    return (
        <Layout cn={"showcase"}>
            <h1 className={"music-video-header"}>{title}</h1>
            <h2 className={"music-video-header__date"}>{artist}</h2>

            <MusicVideo title={title} src={url}></MusicVideo>

            <span className={"music-video-leading"}>Related Videos</span>

            <MusicVideoListRelated loadNum={loadNum} max={max} currentVid={video}></MusicVideoListRelated>

            <div className={`load-more`} onClick={() => scrollTop()}><span>BACK TO TOP</span></div>
        </Layout>
    )
}

export default MusicVideoPlayer;
