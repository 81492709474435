// import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Button, Layout, WeddingsData } from "../../exports";

import "./weddings-desktop.scss";

function WeddingsDesktop(props) {

    return (
        <Layout cn={"weddings"} current="weddings" menuColor="light" footerColor="medium-dark">
            <div className="weddings-desktop">
                <video muted autoPlay loop playsInline disablePictureInPicture src="resources/videos/weddings-reel.mp4"></video>
                <div className="weddings-shader"></div>

                <div className="weddings-text">
                    <p className="weddings-text-subheader">TEAM APOLLO</p>
                    <h2 className="weddings-text-header">Weddings</h2>
                    <p>{WeddingsData.weddingsHomeSectionText}</p>
                    <div className="weddings-text-button">
                        <Button external buttonDest="https://weddings.teamapollovideos.com" buttonColor="white" buttonOutlined>VIEW GALLERY</Button>
                    </div>
                    <div className="weddings-text-cta">
                        <Link to="/contact">BOOKING &amp; INQUIRIES
                            <svg width="38" height="20" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15 4C15 3.88214 14.9436 3.76911 14.8431 3.68577C14.7426 3.60244 14.6064 3.55562 14.4643 3.55562L1.82977 3.55562L5.20134 0.759566C5.25115 0.718249 5.29065 0.669199 5.31761 0.615216C5.34456 0.561233 5.35844 0.503374 5.35844 0.444943C5.35844 0.386513 5.34456 0.328654 5.31761 0.274671C5.29065 0.220688 5.25115 0.171638 5.20134 0.130321C5.15154 0.089004 5.09241 0.0562298 5.02733 0.0338693C4.96226 0.0115088 4.89251 0 4.82208 0C4.75164 0 4.6819 0.0115088 4.61682 0.0338693C4.55175 0.0562298 4.49262 0.089004 4.44282 0.130321L0.157376 3.68538C0.10749 3.72666 0.0679111 3.77569 0.0409059 3.82968C0.0139007 3.88367 2.94372e-09 3.94155 2.98738e-09 4C3.03103e-09 4.05845 0.0139007 4.11633 0.0409059 4.17032C0.0679111 4.2243 0.10749 4.27334 0.157376 4.31462L4.44282 7.86968C4.49262 7.911 4.55175 7.94377 4.61682 7.96613C4.6819 7.98849 4.75164 8 4.82208 8C4.89251 8 4.96226 7.98849 5.02733 7.96613C5.09241 7.94377 5.15154 7.911 5.20134 7.86968C5.25115 7.82836 5.29065 7.77931 5.31761 7.72533C5.34456 7.67135 5.35844 7.61349 5.35844 7.55506C5.35844 7.49662 5.34456 7.43877 5.31761 7.38478C5.29065 7.3308 5.25115 7.28175 5.20134 7.24043L1.82977 4.44438L14.4643 4.44438C14.6064 4.44438 14.7426 4.39756 14.8431 4.31423C14.9436 4.23089 15 4.11786 15 4Z" />
                            </svg>
                        </Link>
                    </div>
                    <a className="weddings-text-facebook" href="https://facebook.com/teamapolloweddings" target="_blank" rel="noreferrer" >
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="white" />
                        </svg>
                        <span>
                            Find us on Facebook
                        </span>
                    </a>
                </div>
            </div>
        </Layout>
    )
}

export default WeddingsDesktop;
