import { useEffect } from "react";

import { Link } from "react-router-dom";

import "./desktop-video-info.scss";

function DesktopVideoInfo(props) {

    useEffect(() => {
        if(props.infoState) {
            if (document.getElementsByClassName("desktop-video-info")[0].style.animation !== "fade-in 0.25s linear 0s 1 normal both") {
                // document.getElementsByClassName("desktop-video-info")[0].style.opacity = 0;
                document.getElementsByClassName("desktop-video-info")[0].style.animation = "fade-in 0.25s linear 0s 1 normal both";
            }
            
            document.getElementsByClassName("media-viewer-info")[0].style.animation = "fade-out 0.15s linear 0s 1 normal forwards";
            document.getElementsByClassName("media-controls-left")[0].style.animation = "fade-out 0.15s linear 0s 1 normal forwards";
            document.getElementsByClassName("media-controls-right")[0].style.animation = "fade-out 0.15s linear 0s 1 normal forwards";
            document.getElementsByClassName("media-controls-indices")[0].style.animation = "fade-out 0.15s linear 0s 1 normal forwards";
            document.getElementsByClassName("media-viewer-cta")[0].style.animation = "fade-out 0.15s linear 0s 1 normal forwards";
            document.getElementsByClassName("media-viewer-learn-more")[0].style.animation = "fade-out 0.15s linear 0s 1 normal forwards";

            setTimeout(() => {
                document.getElementsByClassName("media-viewer-info")[0].style.display = "none";
                document.getElementsByClassName("media-controls-left")[0].style.display = "none";
                document.getElementsByClassName("media-controls-right")[0].style.display = "none";
                document.getElementsByClassName("media-controls-indices")[0].style.display = "none";
                document.getElementsByClassName("media-viewer-cta")[0].style.display = "none";
                document.getElementsByClassName("media-viewer-learn-more")[0].style.display = "none";
            }, 300)
        }

    }, [props.infoState])

    function animateClose() {
        // document.getElementsByClassName("desktop-video-info")[0].style.animation = "fade-out 0.3s linear 0s 1 normal both";

        document.getElementsByClassName("media-viewer-info")[0].style.display = "block";
        document.getElementsByClassName("media-controls-left")[0].style.display = "block";
        document.getElementsByClassName("media-controls-right")[0].style.display = "block";
        document.getElementsByClassName("media-controls-indices")[0].style.display = "flex";
        document.getElementsByClassName("media-viewer-cta")[0].style.display = "block";
        document.getElementsByClassName("media-viewer-learn-more")[0].style.display = "block";

        document.getElementsByClassName("media-viewer-info")[0].style.animation = "fade-in 0.25s linear 0s 1 normal forwards";
        document.getElementsByClassName("media-controls-left")[0].style.animation = "fade-in 0.25s linear 0s 1 normal forwards";
        document.getElementsByClassName("media-controls-right")[0].style.animation = "fade-in 0.25s linear 0s 1 normal forwards";
        document.getElementsByClassName("media-controls-indices")[0].style.animation = "fade-in 0.25s linear 0s 1 normal forwards";
        document.getElementsByClassName("media-viewer-cta")[0].style.animation = "fade-in 0.25s linear 0s 1 normal forwards";
        document.getElementsByClassName("media-viewer-learn-more")[0].style.animation = "fade-in 0.25s linear 0s 1 normal forwards";

        setTimeout(() => {
            document.getElementsByClassName("media-viewer-info")[0].style.animation = "";
            document.getElementsByClassName("media-controls-left")[0].style.animation = "";
            document.getElementsByClassName("media-controls-right")[0].style.animation = "";
            document.getElementsByClassName("media-controls-indices")[0].style.animation = "";
            document.getElementsByClassName("media-viewer-cta")[0].style.animation = "";
            document.getElementsByClassName("media-viewer-learn-more")[0].style.animation = "";
        }, 250)
    }

    return (
        <div className={`desktop-video-info-wrapper${props.infoState ? "" : " desktop-video-info-wrapper--hidden"}`}>
            <div className="desktop-video-info-overlay" onClick={() => { props.closeInfo(); animateClose(); }}></div>

            <div className="desktop-video-info">
                <div className="desktop-video-info-sections">
                    <h1 className="desktop-video-info-sections__title">{props.title}</h1>
                    <hr />
                    <p className="desktop-video-info-sections__description">{props.description}</p>
                    <p className="desktop-video-info-sections__data desktop-video-info-sections__data--date"><span>RELEASED: </span>{props.date}</p>
                    <p className="desktop-video-info-sections__data desktop-video-info-sections__data--location"><span>LOCATION: </span>{props.location}</p>

                    <span className="desktop-video-info-sections__cta-tagline">Like what you see?</span>
                    <div className="desktop-video-info-sections__cta">
                        <Link to="/contact">BOOKING &amp; INQUIRIES
                            <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.37833e-06 6C1.39392e-06 6.17679 0.0714888 6.34633 0.198738 6.47134C0.325986 6.59635 0.498573 6.66657 0.678529 6.66657L16.6823 6.66657L12.4116 10.8607C12.3486 10.9226 12.2985 10.9962 12.2644 11.0772C12.2302 11.1582 12.2126 11.2449 12.2126 11.3326C12.2126 11.4202 12.2302 11.507 12.2644 11.588C12.2985 11.669 12.3486 11.7425 12.4116 11.8045C12.4747 11.8665 12.5496 11.9157 12.632 11.9492C12.7145 11.9827 12.8028 12 12.892 12C12.9813 12 13.0696 11.9827 13.152 11.9492C13.2345 11.9157 13.3093 11.8665 13.3724 11.8045L18.8007 6.47193C18.8638 6.41001 18.914 6.33646 18.9482 6.25547C18.9824 6.17449 19 6.08768 19 6C19 5.91232 18.9824 5.82551 18.9482 5.74452C18.914 5.66354 18.8638 5.58998 18.8007 5.52807L13.3724 0.19548C13.3093 0.133505 13.2345 0.0843435 13.152 0.0508027C13.0696 0.017262 12.9813 -1.38117e-06 12.892 -1.37337e-06C12.8028 -1.36557e-06 12.7145 0.0172621 12.632 0.0508028C12.5496 0.0843435 12.4747 0.133505 12.4116 0.19548C12.3485 0.257455 12.2985 0.33103 12.2644 0.412005C12.2302 0.49298 12.2126 0.579768 12.2126 0.667414C12.2126 0.755061 12.2302 0.841848 12.2644 0.922823C12.2985 1.0038 12.3485 1.07737 12.4116 1.13935L16.6823 5.33343L0.678529 5.33343C0.498573 5.33343 0.325986 5.40366 0.198738 5.52866C0.0714888 5.65367 1.36274e-06 5.82321 1.37833e-06 6Z" />
                            </svg>
                        </Link>
                    </div>
                </div>

                <svg className="desktop-video-info-close" onClick={() => {props.closeInfo(); animateClose();}} width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.971 31.0003L44.9298 21.0415C45.421 20.5061 45.6864 19.8016 45.6707 19.0751C45.6549 18.3486 45.3592 17.6563 44.8452 17.1426C44.3312 16.6289 43.6387 16.3337 42.9122 16.3184C42.1857 16.3031 41.4814 16.569 40.9463 17.0606L40.9488 17.058L30.9901 27.0168L21.0313 17.058C20.4959 16.5668 19.7914 16.3013 19.0649 16.3171C18.3384 16.3328 17.6461 16.6286 17.1324 17.1425C16.6188 17.6565 16.3235 18.3491 16.3082 19.0756C16.2929 19.8021 16.5588 20.5064 17.0504 21.0415L17.0478 21.0389L27.0066 30.9977L17.0478 40.9564C16.7676 41.2135 16.5424 41.5246 16.3856 41.871C16.2288 42.2174 16.1437 42.592 16.1354 42.9721C16.1272 43.3523 16.196 43.7302 16.3376 44.0831C16.4792 44.436 16.6908 44.7566 16.9596 45.0255C17.2284 45.2945 17.5488 45.5063 17.9016 45.6481C18.2544 45.79 18.6323 45.859 19.0124 45.851C19.3926 45.843 19.7672 45.7582 20.1137 45.6016C20.4603 45.445 20.7715 45.22 21.0288 44.9399L21.0313 44.9373L30.9901 34.9786L40.9488 44.9373C41.2059 45.2175 41.517 45.4428 41.8634 45.5996C42.2098 45.7564 42.5844 45.8415 42.9646 45.8498C43.3447 45.858 43.7226 45.7892 44.0755 45.6476C44.4284 45.5059 44.749 45.2944 45.0179 45.0256C45.2869 44.7568 45.4987 44.4363 45.6405 44.0836C45.7824 43.7308 45.8514 43.3529 45.8434 42.9727C45.8354 42.5926 45.7506 42.218 45.594 41.8714C45.4374 41.5249 45.2124 41.2137 44.9323 40.9564L44.9298 40.9538L34.971 31.0003Z" fill="white" />
                </svg>
            </div>
        </div>
    )
}

export default DesktopVideoInfo;
