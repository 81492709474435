import { useEffect, useCallback } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import './photography-fullscreen-view.scss';

let resetFunc;

function PhotographyFullscreenView(props) {

    const viewer = useCallback(node => {
        if (node !== null && resetFunc === undefined) {
            resetFunc = node.resetTransform
        }
    }, []);
    
    useEffect(() => {
        if(resetFunc !== undefined) {
        resetFunc();
        }
    }, [props.display, props.rotated])


    return (
        <div className={`photography-fullscreen-container${props.display ? "" : " photography-fullscreen-container--hidden"}`} id="zoom-image">
            <TransformWrapper ref={viewer} centerOnInit={true} centerZoomedOut={true} limitToBounds="false">
                <TransformComponent>
                    <img className="fullscreen-img" id="fullscreen-image" src={props.image} alt="" />
                </TransformComponent>
            </TransformWrapper>
            <div className="photography-fullscreen-overlay" />
            { props.children }
        </div>
    )
}

export default PhotographyFullscreenView;
