function CommercialVideo(props) {

    return (
        <div className="commercial-video">
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe title={props.title} src={props.src} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></iframe>
            </div>
        </div>
    );
}

export default CommercialVideo;
