import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, CommercialListRelated, CommercialVideo, VideoDataCommercial } from '../../exports';

import "./commercial-player.scss";

function CommercialPlayer() {

    let { video } = useParams();

    const contextIndex = Object.keys(VideoDataCommercial).indexOf(video);
    let keys = Object.keys(VideoDataCommercial);

    keys.splice(contextIndex, 1);

    const [loadNum, setLoadNum] = useState(4);

    const max = Object.keys(VideoDataCommercial).length - 1;

    let title = VideoDataCommercial[video].title;
    let location = VideoDataCommercial[video].location;
    let url = VideoDataCommercial[video].link;

    const scrollTop = () => {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    useEffect(() => {

        const loadMore = () => {
            if (loadNum >= max) {
                return;
            } else if (loadNum + 4 <= max) {
                setLoadNum(loadNum + 4);
            } else if (loadNum < max) {
                setLoadNum(loadNum + (max - loadNum));
            }
        }

        document.body.addEventListener("scroll", () => {
            if (document.body.scrollTop >= document.documentElement.scrollHeight - (window.innerHeight / 2)) {
                loadMore();
            }
        })
    }, [loadNum, max])

    return (
        <Layout cn={"showcase"}>
            <h1 className={"commercial-header"}>{title}</h1>
            <h2 className={"commercial-header__date"}>{location}</h2>

            <CommercialVideo title={title} src={url}></CommercialVideo>

            <span className={"commercial-leading"}>Related Videos</span>

            <CommercialListRelated loadNum={loadNum} max={max} currentVid={video}></CommercialListRelated>

            <div className={`load-more`} onClick={() => scrollTop()}><span>BACK TO TOP</span></div>
        </Layout>
    )
}

export default CommercialPlayer;
