const BiographyData = {
    bioParagraph1: "We are Team Apollo. We provide next level videography and photography services born from a creative movement of individuals and ideas.",
    bioParagraph2: "Team Apollo was created in 2018 by Ryan Lichtel and Marc Boyer in college dorm rooms and on long drives. Born from creative hunger and a drive to bring something different and refreshing to the saturated market of media content.",
    bioParagraph3: "Our mission is to help others bring their visions to life while going above and beyond to create quality video projects and photos that will be enjoyed for a lifetime.",

    commercialParagraph: "Let us lead your next ad campaign with a unique visual promo guaranteed to turn customer's heads.",
    commercialParagraphOffers: "Advertisements, Weddings, Sporting Events, Concerts, and more.",
    musicVideoParagraph: "We specialize in creative music videos focused on bringing your artistic vision to life.",
}

module.exports = BiographyData;