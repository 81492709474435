import { useState } from "react";

import { Nav, Menu, Footer } from "../../exports";

import styles from "./layout.module.scss";

function Layout(props) {

    const [menuState, setMenuState] = useState(false);
    const [menuDisplay, setMenuDisplay] = useState(false);

    let autoHideBar;


    function openCloseMenu() {
        if (menuDisplay) {
            // don't need this, keeping just in case
            // setTimeout(() => setMenuDisplay(false), 300);

            setMenuDisplay(false);
            document.getElementById("root").style.overflow = null;
            document.getElementById("root").style.position = null;
            document.getElementById("root").style.width = null;

            // document.getElementsByTagName("main")[0].style.width = "100%";
            document.getElementsByTagName("main")[0].style.overflow = null;
        } else {
            setMenuDisplay(true);
            document.getElementById("root").style.overflow = "hidden";
            document.getElementById("root").style.position = "fixed";
            document.getElementById("root").style.width = "100vw";

            // document.getElementsByTagName("main")[0].style.width = "20vw";
            document.getElementsByTagName("main")[0].style.overflow = "scroll";
        }
        setMenuState(!menuState);
    };

    return (
        <div
            className={menuState ? `${styles["container"]} ${styles["container--" + props.cn]} ${styles["container--open"]}` : `${styles["container"]} ${styles["container--" + props.cn]}`}
        >
            {autoHideBar}
            <Nav openCloseMenu={openCloseMenu} menuState={menuState} current={props.current} menuColor={props.menuColor} />
            <Menu openCloseMenu={openCloseMenu} menuState={menuState} menuDisplay={menuDisplay} />

            <main>
                <div className="page-container">
                    {props.children}
                </div>
            </main>

            <Footer footerColor={props.footerColor} />
        </div>
    );
}

export default Layout;