import { Link } from "react-router-dom"

import "./menu.scss";

function Menu(props) {
    return (
        <div className={`menu ${props.menuState ? "" : "menu--hidden"}`}>
            <div className={`${props.menuState ? "menu-overlay" : "menu-overlay--hidden"}`} onClick={props.openCloseMenu}></div>
            <div className="menu-nav">
                <img className="menu-nav__close" 
                    src="/resources/graphics/close.svg" 
                    alt="" 
                    onClick={props.openCloseMenu}>
                </img>
            </div>
            <ul className="menu-list">
                <li className="menu-list__item"><Link onClick={props.openCloseMenu}to="/">Home</Link></li>
                <li className="menu-list__item"><Link onClick={props.openCloseMenu} to="/weddings">Weddings</Link></li>
                <li className="menu-list__item"><Link onClick={props.openCloseMenu} to="/commercial">Commercial</Link></li>
                <li className="menu-list__item"><Link onClick={props.openCloseMenu}to="/music-videos">Music Videos</Link></li>
                <li className="menu-list__item"><Link onClick={props.openCloseMenu}to="/photography">Photography</Link></li>
                {/* <li className="menu-list__item"><Link onClick={props.openCloseMenu}to="/bio">Bio</Link></li> */}
                <li className="menu-list__item"><Link onClick={props.openCloseMenu}to="/contact">Contact</Link></li>
            </ul>
            <img className="menu-spaceman" src="/resources/graphics/menu-spaceman.svg" alt=""></img>
        </div>
    );
}

export default Menu;
