import { useEffect } from 'react';

import { useSelector } from 'react-redux'

import "./desktop-video.scss";

function DesktopVideo(props) {

    const pageWidth = useSelector(state => state.pageWidth.value)

    useEffect(() => {
        let vid = document.querySelector('iframe');
        let player = new window.Vimeo.Player(vid);

        let exit = document.getElementsByClassName('exit-controls')[0];
        exit.addEventListener('click', () => {
            player.pause();
        });
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (pageWidth >= 1024) {
                document.getElementsByClassName("shader")[0].style.opacity = document.documentElement.scrollTop / 850
                document.getElementsByClassName("overlay")[0].style.opacity = 1 - (document.documentElement.scrollTop / 200)

                if (document.documentElement.scrollTop >= 500) {
                    if (document.getElementsByClassName("media-controls-indices")[0] && document.getElementsByClassName("media-viewer-cta")[0]) {
                        document.getElementsByClassName("media-controls-indices")[0].style.opacity = 0;
                        document.getElementsByClassName("media-viewer-cta")[0].style.opacity = 0;
                    }
                } else {
                    if (document.getElementsByClassName("media-controls-indices")[0] && document.getElementsByClassName("media-viewer-cta")[0]) {
                        document.getElementsByClassName("media-controls-indices")[0].style.opacity = 1;
                        document.getElementsByClassName("media-viewer-cta")[0].style.opacity = 1;
                    }
                }
            }
        });
    }, [pageWidth]);

    useEffect(() => {
        if (props.fullscreen === true) {
            document.getElementsByClassName("exit-controls")[0].style.animation = "slide-up 0.3s forwards";
            console.log(props.fullscreen)
            // document.getElementsByClassName("shader")[0].style.opacity = 1;
            // document.getElementsByClassName("media-video-desktop")[0].style.animation = "0.3s ease 0s 1 normal both running fade-in";
        }
    })

    return (
        <div className="media-video-desktop-container">

            <div className="overlay"></div>
            <div className="shader"></div>
            <div className={`shader-fullscreen${props.fullscreen ? " shader-fullscreen--open" : ""}`} />
            <div className={`exit-controls${props.fullscreen ? " exit-controls--open" : ""}`} onClick={props.fullscreen ? () => { props.changeFullscreen(false) } : null}>
                <div className="exit-controls-container">
                    <svg className="exit-button" width="40" height="21" viewBox="0 0 40 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M40 10.5C40 10.8094 39.8495 11.1061 39.5816 11.3248C39.3137 11.5436 38.9504 11.6665 38.5715 11.6665L4.87938 11.6665L13.8702 19.0061C14.0031 19.1146 14.1084 19.2434 14.1803 19.3851C14.2522 19.5268 14.2892 19.6786 14.2892 19.832C14.2892 19.9854 14.2522 20.1373 14.1803 20.279C14.1084 20.4207 14.0031 20.5495 13.8702 20.6579C13.7374 20.7664 13.5798 20.8524 13.4062 20.9111C13.2327 20.9698 13.0467 21 12.8589 21C12.671 21 12.4851 20.9698 12.3115 20.9111C12.138 20.8524 11.9803 20.7664 11.8475 20.6579L0.419669 11.3259C0.28664 11.2175 0.181095 11.0888 0.109081 10.9471C0.0370676 10.8054 -9.0067e-07 10.6534 -9.14026e-07 10.5C-9.27383e-07 10.3466 0.0370675 10.1946 0.109081 10.0529C0.181095 9.9112 0.286639 9.78248 0.419669 9.67412L11.8475 0.342095C11.9803 0.233638 12.138 0.147605 12.3115 0.0889081C12.4851 0.0302113 12.671 2.70222e-06 12.8589 2.68573e-06C13.0467 2.66924e-06 13.2327 0.0302112 13.4062 0.088908C13.5798 0.147605 13.7374 0.233638 13.8702 0.342095C14.0031 0.450551 14.1084 0.579306 14.1803 0.721013C14.2522 0.862717 14.2892 1.0146 14.2892 1.16798C14.2892 1.32136 14.2522 1.47324 14.1803 1.61494C14.1084 1.75665 14.0031 1.88541 13.8702 1.99386L4.87938 9.3335L38.5715 9.3335C38.9504 9.3335 39.3137 9.45639 39.5816 9.67516C39.8495 9.89392 40 10.1906 40 10.5Z" fill="white"/>
                    </svg>
                    <span className="exit-text">BACK</span>
                </div>
            </div>

            <div className={props.context !== "mobile" ? `media-video-desktop${props.fullscreen ? " media-video-desktop--open" : ""}` : `media-video-mobile${props.fullscreen ? " media-video-mobile--open" : ""}`} >

                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe title={props.title} src={props.src} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: 'none'}}></iframe>
                </div>
            </div>
        </div>
    )
}

export default DesktopVideo;
